import React from "react";
import { Row, Col, Typography } from "antd";
import bg3 from "../assets/img/bg-3.jpg";
import logo from "../assets/img/logo-grey.png";

export default function Loading() {
  const { Title } = Typography;
  return (
    <div
      className="loading"
      style={{
        backgroundImage: `url('${bg3}')`,
        height: "100vh",
        backgroundSize: "cover",
        width: "100vw",
      }}
    >
      <Row>
        <Col span={24} style={{ alignItems: "center" }}>
          <img
            src={logo}
            alt="Imagen"
            style={{ marginBottom: "20px", width: 700 }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ alignItems: "center" }}>
          <Title level={1} style={{ color: "grey", marginTop: 10 }}>
            {"404 | Pagina INEXISTENTE"}
          </Title>
        </Col>
      </Row>
    </div>
  );
}
