const initialProps = {
  ticketTwoDetalle: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_TWO_DETALLE":
      return {
        ...state,
        ticketTwoDetalle: [...state.ticketTwoDetalle, action.payload],
      };
    case "UPDATE_TICKET_TWO_DETALLE":
      return {
        ...state,
        ticketTwoDetalle: action.payload,
      };
    case "REMOVE_TICKET_TWO_DETALLE":
      return {
        ...state,
        ticketTwoDetalle: action.payload,
      };
    default:
      return state;
  }
}
