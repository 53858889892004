export const createBackupAction = (state) => {
  return {
    type: "CREATE_BACKUP",
    payload: state,
  };
};

export const updateBackupAction = (state) => {
  return {
    type: "UPDATE_BACKUP",
    payload: state,
  };
};
