const initialProps = {
  backups: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_BACKUP":
      return {
        ...state,
        backups: [...state.backups, action.payload],
      };
    case "UPDATE_BACKUP":
      return {
        ...state,
        backups: action.payload,
      };
    default:
      return state;
  }
}
