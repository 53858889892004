import React from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Space,
  Badge,
  Dropdown,
  List,
  Typography,
  Button,
  Modal as ModalB,
} from "antd";
import Icon from "@mdi/react";
import {
  mdiLogout,
  mdiBasketOutline,
  mdiBasketOffOutline,
  mdiCloudDownloadOutline,
  mdiCloudUploadOutline,
  mdiCashPlus,
  mdiCashMinus,
} from "@mdi/js";

import {
  BellOutlined,
  MenuOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { ExclamationCircleOutlined } from "@ant-design/icons";

//Firebase
import firebase from "../../../utils/Firebase";
import "firebase/auth";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateUserAction } from "../../../actions/userActions";
import { updateLoguedAction } from "../../../actions/isLoguedActions";
import { updateInventoryAction } from "../../../actions/inventoryActions";

//Funciones propias
import { downloadDataFrm } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";

//Imaganes y logos
import logo_title from "../../../assets/img/logo-menu.png";
import { colorPrm } from "../../../constants";

//Estilos
import "./Header.scss";
import curStyle from "../../../css/GlobalCmp.module.css";

export default function Header(props) {
  const {
    setIsLoading,
    currentUser,
    userOptions,
    openModal,
    openDrawer,
    nombreMes,
    updateGanaderiaEnv,
  } = props;

  const history = useHistory();

  //Ejecutar dispatch SEGUN BECESIDADES
  const dispatch = useDispatch();
  const updateData = (state) => dispatch(updateUserAction(state));
  const updateLogued = (state) => dispatch(updateLoguedAction(state));

  const { Title } = Typography;
  const { confirm } = ModalB;
  const curDate = new Date();

  const logOut = async () => {
    confirm({
      title: "CERRAR SESION",
      icon: <ExclamationCircleOutlined />,
      okText: "Cerrar Sesión",
      cancelText: "Cancelar",
      content: "Desea salir de 2bc ERP?",
      onOk() {
        firebase.auth().signOut();
        updateData({
          id: 0,
          nombre: "DESCONECTADO",
          pagina: "",
        });
        updateLogued(false);
        history.push("/");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const downData = async () => {
    setIsLoading(true);
    let reLoad = await downloadDataFrm(currentUser);
    if (reLoad) {
      updateGanaderiaEnv(reLoad.ganaderia);
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS DESCARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  /*const upData = async () => {
    setIsLoading(true);

    let reLoad = await uploadDataPos(currentUser);
    if (reLoad) {
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS CARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };*/

  const opciones = [
    {
      key: "1",
      title: "Descargar Datos",
      icon: (
        <Icon path={mdiCloudDownloadOutline} size={"26px"} color={colorPrm} />
      ),
      onClick: () => downData(),
    },
    {
      key: "5",
      title: "Subir Datos",
      icon: (
        <Icon path={mdiCloudUploadOutline} size={"26px"} color={colorPrm} />
      ),
      onClick: () => console.log("Por ahora"),
    },
    {
      key: "6",
      title: "Cerrar Sesion",
      icon: <Icon path={mdiLogout} size={"26px"} color={colorPrm} />,
      onClick: () => logOut(),
    },
  ];

  const ShowAlerts = (
    <form
      style={{
        backgroundColor: "#ffffff",
        marginTop: "5px",
        width: "350px",
        padding: "5px",
      }}
    >
      <div>
        <List
          style={{ marginTop: 5 }}
          itemLayout="horizontal"
          dataSource={opciones}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: 3, marginTop: 3, padding: 2 }}>
              <List.Item.Meta
                avatar={item.icon}
                title={
                  <Title
                    level={4}
                    style={{
                      padding: 2,
                      margin: 2,
                      fontWeight: 300,
                      fontSize: 18,
                    }}
                  >
                    {item.title}
                  </Title>
                }
                onClick={item.onClick}
              />
            </List.Item>
          )}
        />
      </div>
    </form>
  );

  return (
    <>
      <Row className="main-header">
        <span style={{ left: "10px", position: "absolute", top: 10 }}>
          {userOptions.length > 0 ? (
            <Space size={"middle"}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                onClick={() => openDrawer(1, 1, "500px")}
                icon={<MenuOutlined style={{ color: colorPrm }} />}
              />
              <img src={logo_title} alt="Imagen" className="img-logo-title" />
            </Space>
          ) : (
            <img src={logo_title} alt="Imagen" className="img-logo-title" />
          )}
        </span>
        <span style={{ textAlign: "center", top: 10 }}>
          <Title level={4} style={{ color: colorPrm, marginTop: 10 }}>
            {"GESTION GANADERA " + nombreMes(1) + " " + curDate.getFullYear()}
          </Title>
        </span>
        <span
          className="li-options"
          style={{
            textAlign: "right",
            right: "5px",
            top: 10,
            position: "absolute",
            width: "750px",
          }}
        >
          <Space size={"middle"}>
            <Badge count={5} style={{ backgroundColor: "orange" }}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<BellOutlined style={{ color: colorPrm }} />}
              />
            </Badge>
            <Badge count={5} style={{ backgroundColor: "#108ee9" }}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<MailOutlined style={{ color: colorPrm, size: 24 }} />}
              />
            </Badge>
            {currentUser.nombre}
            <Dropdown
              overlay={ShowAlerts}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <Button
                type="primary"
                className={curStyle.btnSecundary}
                shape="circle"
                icon={<UserOutlined />}
              />
            </Dropdown>
            {currentUser.menu_r ? (
              <Button
                type="primary"
                className={curStyle.btnWhite}
                onClick={() => openDrawer(2)}
                shape="circle"
                icon={<MenuOutlined style={{ color: "#52c41a", size: 24 }} />}
              />
            ) : null}
          </Space>
        </span>
      </Row>
    </>
  );
}
