import { combineReducers } from "redux";

import backupReducer from "./backupReducer";
import userReducer from "./userReducer";
import enviromentReducer from "./enviromentReducer";
import ganaderiaReducer from "./ganaderiaReducer";
import isLoguedReducer from "./isLoguedReducer";
import priceListReducer from "./priceListReducer";
import inventoryReducer from "./inventoryReducer";
import currentComponentReducer from "./currentComponentReducer";
import shiftReducer from "./Pos/shiftReducer";
import shiftsListReducer from "./Pos/shiftsListReducer";
import curTicketReducer from "./Pos/curTicketReducer";
import curTicketDetalleReducer from "./Pos/curTicketDetalleReducer";
import ticketsReducer from "./Pos/ticketsReducer";
import ticketsDetalleReducer from "./Pos/ticketsDetalleReducer";
import ticketsTempReducer from "./Pos/ticketsTempReducer";
import ticketsTempDetalleReducer from "./Pos/ticketsTempDetalleReducer";
import ticketsDiscartedReducer from "./Pos/ticketsDiscartedReducer";
import ticketsDiscartedDetalleReducer from "./Pos/ticketsDiscartedDetalleReducer";
import curProductReducer from "./Pos/curProductReducer";
import moveCashReducer from "./Pos/moveCashReducer";
import detalleTicketReducer from "./Pos/detalleTicketReducer";
import selectedTicketReducer from "./Pos/selectedTickedReducer";
import ticketOneReducer from "./Pos/ticketOneReducer";
import ticketOneDetalleReducer from "./Pos/ticketOneDetalleReducer";
import ticketTwoReducer from "./Pos/ticketTwoReducer";
import ticketTwoDetalleReducer from "./Pos/ticketTwoDetalleReducer";
import ticketThreeReducer from "./Pos/ticketThreeReducer";
import ticketThreeDetalleReducer from "./Pos/ticketThreeDetalleReducer";
import ticketFourReducer from "./Pos/ticketFourReducer";
import ticketFourDetalleReducer from "./Pos/ticketFourDetalleReducer";
import ticketFiveReducer from "./Pos/ticketFiveReducer";
import ticketFiveDetalleReducer from "./Pos/ticketFiveDetalleReducer";

export default combineReducers({
  isLogued: isLoguedReducer,
  backups: backupReducer,
  currentUser: userReducer, //Pos
  enviroment: enviromentReducer,
  ganaderiaEnv: ganaderiaReducer,
  priceList: priceListReducer,
  curStock: inventoryReducer,
  currentComponent: currentComponentReducer,
  shift: shiftReducer,
  shiftsList: shiftsListReducer,
  curTicket: curTicketReducer,
  curTicketDetalle: curTicketDetalleReducer,
  tickets: ticketsReducer,
  ticketsDetalle: ticketsDetalleReducer,
  ticketsTemp: ticketsTempReducer,
  ticketsTempDetalle: ticketsTempDetalleReducer,
  ticketsDiscarted: ticketsDiscartedReducer,
  discartedDetalle: ticketsDiscartedDetalleReducer,
  currentProduct: curProductReducer,
  moveCash: moveCashReducer,
  detalleTicket: detalleTicketReducer,
  selectedTicket: selectedTicketReducer,
  ticketOne: ticketOneReducer,
  ticketOneDetalle: ticketOneDetalleReducer,
  ticketTwo: ticketTwoReducer,
  ticketTwoDetalle: ticketTwoDetalleReducer,
  ticketThree: ticketThreeReducer,
  ticketThreeDetalle: ticketThreeDetalleReducer,
  ticketFour: ticketFourReducer,
  ticketFourDetalle: ticketFourDetalleReducer,
  ticketFive: ticketFiveReducer,
  ticketFiveDetalle: ticketFiveDetalleReducer,
});
