import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Switch,
  Button,
  Divider,
  Typography,
  Popconfirm,
  Select,
  message,
  Space,
} from "antd";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Iconos y constantes
import {
  ShoppingOutlined,
  CheckOutlined,
  CloseOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

//Componentes Personalizados
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import POS from "../../../utils/PosService";
import curStyle from "../../../css/GlobalCmp.module.css";

//Estilos
import "./CloseTicket.scss";

export default function CloseTicket(props) {
  const {
    myNumber,
    currentUser,
    curTicket,
    detalleCurTicket,
    closeModal,
    customersList,
    saveTicket,
    varEffec,
    curCurrency,
    updateInventory,
    setListProducts,
    filtrarArray,
    updateEstadoTicketDet,
    updateEnviroment,
    setLoadInventory,
    updateCurProduct,
  } = props;
  const [tituloModal, setTituloModal] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [newCliente, setNewCliente] = useState(false);
  const [planSepare, setPlanSepare] = useState(0);
  const [cobroVenta, setCobroVenta] = useState(0);
  const [descuTicket, setDescuTicket] = useState(0);
  const [ventaNota, setVentaNota] = useState(0);
  const [idCliente, setIdCliente] = useState(0);
  const [namCliente, setNamCliente] = useState("");
  const [telCliente, setTelCliente] = useState("");
  const [defDetalle, setDefDetalle] = useState(null);
  const [defHeader, setDefHeader] = useState(null);
  const [btnPrint, setBtnPrint] = useState(0);
  const pagoInput = useRef(null);

  //Produto actual para detalle o edicion de ticket
  const prnSaved = useSelector((state) => state.currentProduct.currentProduct);

  //Defibiciones adicioneles Antd
  const { Option } = Select;
  const { Title } = Typography;
  const { TextArea } = Input;

  var titulo = "CERRAR TICKET";

  useEffect(() => {
    (async () => {
      setTituloModal(titulo);
      setDefDetalle(detalleCurTicket);
      setIdCliente(curTicket.clt_id);
      setNamCliente(curTicket.cliente);
      setCobroVenta(0);
      pagoInput.current.focus();
    })();
  }, [varEffec]);

  const updateCliente = async (value) => {
    setIdCliente(value);
    let clts = filtrarArray(customersList, value, 2, 1);
    setNamCliente(clts[0].trc_nombre);
  };

  //Se actualiza el nombre
  const onChangeCliente = (e) => {
    let cltName = e.target.value;
    if (idCliente === 0 && !newCliente) {
      showNotification(
        "topRight",
        2,
        "CERRAR TICKET",
        "DEBE SELECCIONAR EL CLIENTE O MARCAR CREAR UNO NUEVO"
      );
      setNamCliente("");
    } else {
      setNamCliente(cltName.toUpperCase());
    }
  };

  //Se actualiza el telefono
  const onChangeTele = (e) => {
    setTelCliente(e.target.value);
  };

  //Para Crear nuevo cliente
  const onNewCliente = (checked) => {
    setNewCliente(checked);
    setNamCliente(checked ? namCliente : "");
    message.success(checked ? "SE CREARA EL CLIENTE" : "CLIENTE DESCARTADO", 1);
  };

  //Para Crear nuevo cliente
  const onPlanSepare = (value) => {
    if (value === 0) {
      message.error("INGRESE EL MONTO DE LA VENTA", 1);
    } else if (value === 1) {
      message.warning("INGRESE EL MONTO DE SEPARACION", 1);
    } else {
      message.success("PUEDE REGISTRAR LA VENTA", 1);
    }
    setPlanSepare(value);
  };

  const onChangePago = (value) => {
    if (Number.parseInt(value) >= 0) {
      setCobroVenta(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeDescu = (value) => {
    if (Number.parseInt(value) >= 0) {
      setDescuTicket(value);
      message.info("ESCRIBA EL MOTIVO DEL DESCUENTO", 1);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaV = e.target.value;
    setVentaNota(notaV.toUpperCase());
  };

  const registerTicket = async () => {
    setLoadingButton(true);

    const ticketPrn = {
      formato: 1,
      ticket_header: curTicket,
      ticket_items: detalleCurTicket,
    };
    updateCurProduct(ticketPrn);

    //Actualizo el encabezado del ticket
    let newVta = {
      id: curTicket.id,
      fecha: curTicket.fecha,
      turno: curTicket.turno,
      user: curTicket.user,
      nombre: curTicket.nombre,
      cliente: namCliente,
      clt_id: idCliente,
      totalTicket: curTicket.totalTicket,
      estado: curTicket.estado,
      lista: curTicket.lista,
      fecha_pago: curTicket.fecha_pago,
      nota: ventaNota,
      cobro: cobroVenta,
      cambio:
        Number.parseFloat(cobroVenta - curTicket.totalTicket).toFixed(2) > 0
          ? Number.parseFloat(cobroVenta - curTicket.totalTicket).toFixed(2)
          : 0,
      planSepare: planSepare,
      newCliente: newCliente ? 1 : 0,
      telCliente: telCliente,
      descuento: descuTicket,
      ip_ticket: curTicket.ip_ticket,
    };

    if (Number.parseFloat(descuTicket) > 0 && ventaNota === 0) {
      showNotification(
        "topRight",
        2,
        "CERRAR TICKET",
        "REGISTRE EL MOTIVO DEL DESCUENTO"
      );
      setLoadingButton(false);
      return false;
    }

    if (
      planSepare === 0 &&
      Number.parseFloat(curTicket.totalTicket) >
        Number.parseFloat(cobroVenta + descuTicket)
    ) {
      showNotification(
        "topRight",
        2,
        "CERRAR TICKET",
        "SALDO PENDIENTE DE COBRO... VERIFIQUE"
      );
      setLoadingButton(false);
      return false;
    }

    if (planSepare === 1 && Number.parseFloat(cobroVenta) === 0) {
      showNotification(
        "topRight",
        2,
        "PLAN SEPARE",
        "DEBE REGISTRAR EL MONTO DE SEPARACION... VERIFIQUE"
      );
      setLoadingButton(false);
      return false;
    }

    if (idCliente === 0 || namCliente.length === 0) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR VENTA",
        "DEBE SELECCIONAR O CREAR EL CLIENTE"
      );
      setLoadingButton(false);
      return false;
    }

    //Se envia al swrvidor
    //Si falla se guarda solo local
    const savedTicket = {
      ticket_header: newVta,
      ticket_items: defDetalle,
    };

    let val_ticket = await POS.sendTicket(currentUser, savedTicket);
    if (val_ticket.type && Number.parseInt(val_ticket.type) > 0) {
      //Se actualiza el inventario
      newVta.estado = 1;
      let newDet = updateEstadoTicketDet(detalleCurTicket);
      saveTicket(newVta, newDet);
      setPlanSepare(false);
      setNewCliente(false);
      setCobroVenta(0);
      setVentaNota("");
      updateInventory(val_ticket.datos.inventario);
      updateEnviroment(val_ticket.datos.entorno);
      showNotification(
        "topRight",
        4,
        "CERRAR TICKET",
        "Registro de venta exitoso!"
      );

      setListProducts([]);
      setLoadInventory(new Date());
      setBtnPrint(1);
    } else {
      //saveTicket(newVta, detalleCurTicket);
      //setPlanSepare(false);
      //setNewCliente(false);
      //setCobroVenta(0);
      //setDescuTicket(0);
      //setVentaNota("");
      showNotification(
        "topRight",
        2,
        "ERROR CERRANDO TICKET",
        val_ticket.message,
        0
      );
    }

    setLoadingButton(false);

    //Empiezo validaciones
    /*if (planSepare) {
      // Cobro parcial
      if (idCliente === 0 || namCliente.length === 0) {
        showNotification(
          "topRight",
          2,
          "CERRAR TICKET",
          "DEBE SELECCIONAR O CREAR EL CLIENTE"
        );
        setLoadingButton(false);
        return false;
      } else {
        //Mando a cerrar la venta local

        const savedTicket = {
          ticket_header: newVta,
          ticket_items: defDetalle,
        };

        let val_ticket = await POS.sendTicket(currentUser, savedTicket);
        if (Number.parseInt(val_ticket.type) > 0) {
          //Se actualiza el inventario
          newVta.estado = 1;
          let newDet = updateEstadoTicketDet(detalleCurTicket);
          saveTicket(newVta, newDet);
          setNamCliente("");
          setPlanSepare(false);
          setNewCliente(false);
          setCobroVenta(0);
          setVentaNota("");
          updateInventory(val_ticket.datos.inventario);
          updateEnviroment(val_ticket.datos.entorno);
          showNotification(
            "topRight",
            4,
            "CERRAR TICKET",
            "Registro de venta exitoso!"
          );
        } else {
          saveTicket(newVta, detalleCurTicket);
          setNamCliente("");
          setPlanSepare(false);
          setNewCliente(false);
          setCobroVenta(0);
          setVentaNota("");
          showNotification("topRight", 2, "CERRAR TICKET", val_ticket.message);
        }
      }
    } else {
      //Valido el monto cobrado
      if (
        Number.parseFloat(curTicket.totalTicket) > Number.parseFloat(cobroVenta)
      ) {
        showNotification(
          "topRight",
          2,
          "CERRAR TICKET",
          "SALDO PENDIENTE DE COBRO... VERIFIQUE"
        );
        setLoadingButton(false);
        return false;
      } else {
        //Se envia al swrvidor
        //Si falla se guarda solo local
        const savedTicket = {
          ticket_header: newVta,
          ticket_items: defDetalle,
        };

        let val_ticket = await POS.sendTicket(currentUser, savedTicket);
        if (val_ticket.type && Number.parseInt(val_ticket.type) > 0) {
          //Se actualiza el inventario
          newVta.estado = 1;
          let newDet = updateEstadoTicketDet(detalleCurTicket);
          saveTicket(newVta, newDet);
          setPlanSepare(false);
          setNewCliente(false);
          setCobroVenta(0);
          setVentaNota("");
          updateInventory(val_ticket.datos.inventario);
          updateEnviroment(val_ticket.datos.entorno);
          showNotification(
            "topRight",
            4,
            "CERRAR TICKET",
            "Registro de venta exitoso!"
          );
        } else {
          saveTicket(newVta, detalleCurTicket);
          setPlanSepare(false);
          setNewCliente(false);
          setCobroVenta(0);
          setVentaNota("");
          showNotification("topRight", 2, "CERRAR TICKET", val_ticket.message);
        }
      }
    }*/
  };

  //Imprimir el ticket
  const prnTicket = async () => {
    window.open(
      "https://api.aal-erp.com/pos/api/714?objeto=" + JSON.stringify(prnSaved),
      "_blank"
    );
  };
  //Fin imprimir Ticket

  return (
    <>
      <Title
        level={3}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: CST.colorSec,
        }}
      >
        {tituloModal}
      </Title>
      <Divider style={{ marginTop: 3, paddingTop: 5, marginBottom: 10 }} />
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        size="midle"
      >
        <Row style={{ marginBottom: "10px" }}>
          <Col span={6} style={{ textAlign: "left" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                color: CST.colorPrm,
                fontSize: 16,
              }}
            >
              Elija Cliente:
            </Title>
          </Col>
          <Col span={18} style={{ textAlign: "left" }}>
            <Select
              showSearch
              showArrow
              style={{ width: "100%" }}
              defaultValue={curTicket.clt_id}
              onChange={updateCliente}
              placeholder="CLIENTE"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {customersList.map((cliente) => (
                <Option key={cliente.trc_id} value={cliente.trc_id}>
                  {cliente.trc_nombre}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col span={4} style={{ textAlign: "left" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                color: CST.colorPrm,
                fontSize: 16,
              }}
            >
              Crear Nuevo:
            </Title>
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={onNewCliente}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: "left", paddingRight: 5 }}>
            <Input
              placeholder="Nombre Cliente"
              defaultValue={curTicket.cliente}
              value={namCliente}
              onChange={onChangeCliente}
            />
          </Col>
          <Col span={6} style={{ textAlign: "left" }}>
            <Input
              placeholder="Telefono"
              defaultValue={telCliente}
              value={telCliente}
              style={{ paddingLeft: 5 }}
              onChange={onChangeTele}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 15, paddingTop: 1, marginBottom: 5 }} />
        <Row style={{ marginBottom: "10px" }}>
          <Col span={6} style={{ textAlign: "left" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                color: CST.colorSec,
                fontSize: 18,
              }}
            >
              TIPO VENTA:
            </Title>
          </Col>
          <Col span={4} style={{ textAlign: "left" }}>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Select
                defaultValue={planSepare}
                style={{ width: 140 }}
                onChange={onPlanSepare}
              >
                <Option key={0} value={0}>
                  CONTADO
                </Option>
                <Option key={1} value={1}>
                  PLAN SEPARE
                </Option>
                <Option key={2} value={2}>
                  CREDITO
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              {"TOTAL VENTA: " + curCurrency.simbolo}
            </Title>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
              }}
            >
              {myNumber(1, curTicket.totalTicket)}
            </Title>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col span={18} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 700,
                color: CST.colorGrn,
                fontSize: 18,
              }}
            >
              {"EFECTIVO: " + curCurrency.simbolo}
            </Title>
          </Col>
          <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Form.Item style={{ marginBottom: "0px" }}>
              <InputNumber
                size="large"
                style={{ width: 150, textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                tabIndex={1}
                min={0}
                defaultValue={cobroVenta}
                value={cobroVenta}
                onChange={onChangePago}
                ref={pagoInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              {"PENDIENTE: " + curCurrency.simbolo}
            </Title>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
              }}
            >
              {Number.parseFloat(
                curTicket.totalTicket - (cobroVenta + descuTicket)
              ).toFixed(2) > 0
                ? myNumber(
                    1,
                    curTicket.totalTicket - (cobroVenta + descuTicket)
                  )
                : "0.00"}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={18} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                color: CST.colorSec,
                fontSize: 18,
              }}
            >
              {"CAMBIO: " + curCurrency.simbolo}
            </Title>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                color: CST.colorSec,
              }}
            >
              {Number.parseFloat(
                cobroVenta + descuTicket - curTicket.totalTicket
              ).toFixed(2) > 0
                ? myNumber(1, cobroVenta + descuTicket - curTicket.totalTicket)
                : "0.00"}
            </Title>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 1, marginBottom: 5 }} />
      <TextArea
        rows={3}
        value={ventaNota}
        placeholder={"Observaciones"}
        onChange={onChangeNota}
        allowClear
      />
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 15 }} />
      <div>
        {btnPrint === 0 ? (
          <Button
            type="primary"
            shape="round"
            size="large"
            block
            loading={loadingButton}
            disabled={
              planSepare === 2 ||
              Number.parseFloat(cobroVenta + descuTicket) > 0
                ? false
                : true
            }
            danger
            onClick={() => registerTicket()}
          >
            <ShoppingOutlined /> CERRAR LA VENTA
          </Button>
        ) : (
          <Space direction="horizontal" align="center">
            <Button
              type="primary"
              shape="round"
              size="large"
              block
              className={curStyle.btnBlueLight}
              onClick={() => prnTicket()}
            >
              <PrinterOutlined /> IMPRIMIR TICKET
            </Button>
            <Button
              type="primary"
              shape="round"
              size="large"
              block
              className={curStyle.btnInfo}
              onClick={() => closeModal()}
            >
              <ShoppingOutlined /> VOLVER A VENTAS
            </Button>
          </Space>
        )}
      </div>
    </>
  );
}
