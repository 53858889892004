export const createTicketOneDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_ONE_DETALLE",
    payload: state,
  };
};

export const updateTicketOneDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_ONE_DETALLE",
    payload: state,
  };
};

export const removeTicketOneDetalleAction = (state) => {
  return {
    type: "REMOVE_TICKET_ONE_DETALLE",
    payload: state,
  };
};
