export const createMoveCashAction = (state) => {
  return {
    type: "CREATE_MOVE_CASH",
    payload: state,
  };
};

export const updateMoveCashAction = (state) => {
  return {
    type: "UPDATE_MOVE_CASH",
    payload: state,
  };
};
