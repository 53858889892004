const initialProps = {
  shift: {
    id: "aAl71071301146-11799030",
    user: 0,
  },
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "UPDATE_SHIFT":
      return {
        ...state,
        shift: action.payload,
      };
    default:
      return state;
  }
}
