export const createTicketDiscartedDetalleAction = (state) => {
  return {
    type: "CREATE_TICKETS_DISCARTED_DETALLE",
    payload: state,
  };
};

export const updateTicketDiscartedDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKETS_DISCARTED_DETALLE",
    payload: state,
  };
};
