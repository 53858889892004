const initialProps = {
  ticketFourDetalle: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_FOUR_DETALLE":
      return {
        ...state,
        ticketFourDetalle: [...state.ticketFourDetalle, action.payload],
      };
    case "UPDATE_TICKET_FOUR_DETALLE":
      return {
        ...state,
        ticketFourDetalle: action.payload,
      };
    case "REMOVE_TICKET_FOUR_DETALLE":
      return {
        ...state,
        ticketFourDetalle: action.payload,
      };
    default:
      return state;
  }
}
