import React, { useState } from "react";
import {
  InputNumber,
  Button,
  Divider,
  Typography,
  Popconfirm,
  message,
} from "antd";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//Iconos y constantes
import { ShoppingOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateShiftAction } from "../../../actions/Pos/shiftActions";

//Componentes Personalizados
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";

//Estilos
import "./OpenShift.scss";

export default function OpenTurn(props) {
  const {
    genTicket,
    currentUser,
    closeModal,
    createOne,
    createTwo,
    createThree,
    createFour,
    createFive,
    updateOneDet,
    updateTwoDet,
    updateThreeDet,
    updateFourDet,
    updateFiveDet,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [base, setBase] = useState(0);

  const { Title } = Typography;

  //Ejecutar dispatch tras el logueo
  //Se Crea el Turno
  const dispatch = useDispatch();
  const updateShift = (state) => dispatch(updateShiftAction(state));

  const onChangeBase = (value) => {
    if (Number.parseInt(value) > 0) {
      setBase(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const abrirTurno = async () => {
    setLoadingButton(true);
    const turno = {
      id: uuidv4(),
      fecha: moment(),
      user: currentUser.id,
      nombre: currentUser.nombre,
      valorBase: Number.parseFloat(base),
    };
    updateShift(turno);
    let tckOne = genTicket(turno.id);
    createOne(tckOne);
    let tckTwo = genTicket(turno.id);
    createTwo(tckTwo);
    let tckThree = genTicket(turno.id);
    createThree(tckThree);
    let tckFour = genTicket(turno.id);
    createFour(tckFour);
    let tckFive = genTicket(turno.id);
    createFive(tckFive);
    updateOneDet([]);
    updateTwoDet([]);
    updateThreeDet([]);
    updateFourDet([]);
    updateFiveDet([]);
    showNotification(
      "topRight",
      4,
      "ABRIR TURNO",
      "Turno abierto de manera EXITOSA"
    );
    setLoadingButton(false);
    closeModal();
  };

  return (
    <>
      <Title
        level={3}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: CST.colorSec,
        }}
      >
        ABRIR TURNO
      </Title>

      <Title
        level={4}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: "#6A3E98",
        }}
      >
        Ingrese la base inicial!
      </Title>

      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <InputNumber
        size="large"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        min={0}
        max={10000000}
        defaultValue={0}
        value={base}
        onChange={onChangeBase}
        style={{ width: "100%" }}
      />
      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <div>
        <Popconfirm
          placement="top"
          title="¿ DESEA ABRIR EL TURNO ?"
          onConfirm={abrirTurno}
          okText="Confirmar"
          cancelText="Cancelar"
          icon={<ShoppingOutlined style={{ color: CST.colorPrm }} />}
        >
          <Button
            type="primary"
            shape="round"
            size="large"
            block
            loading={loadingButton}
          >
            <ShoppingOutlined /> ABRIR EL TURNO
          </Button>
        </Popconfirm>
      </div>
    </>
  );
}
