const initialProps = {
  shiftsList: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_SHIFTS":
      return {
        ...state,
        shiftsList: [...state.shiftsList, action.payload],
      };
    case "UPDATE_SHIFTS_SENT":
      return {
        ...state,
        shiftsList: action.payload,
      };
    default:
      return state;
  }
}
