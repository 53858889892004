import React from "react";
import { Col, Card, Typography } from "antd";
import {
  PictureOutlined,
  ShoppingCartOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";

import "./Product.scss";

export default function Product(props) {
  const {
    product,
    validateRows,
    showDetails,
    myNumber,
    curCurrency,
    updateBcode,
  } = props;
  const { Meta } = Card;
  const { Title } = Typography;
  const len_dscr = product.descripcion.length;

  return (
    <Col xs={12} sm={8} md={6} lg={6} xl={6} className="myproduct">
      <Card
        style={{ marginTop: 16, marginBottom: 15 }}
        hoverable
        actions={[
          <BarcodeOutlined
            key="barcode"
            onClick={() => updateBcode(product)}
          />,
          <PictureOutlined
            key="details"
            onClick={() => showDetails(product)}
          />,
          <ShoppingCartOutlined
            key="cart"
            onClick={() => validateRows(product)}
          />,
        ]}
      >
        <Meta
          title={
            <>
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {product.codigo}
              </Title>
              <p className="prd-price">
                {curCurrency.simbolo +
                  myNumber(1, product.preciog) +
                  " " +
                  " | " +
                  myNumber(2, product.disponible) +
                  " " +
                  product.unidad}
              </p>
            </>
          }
        />
        <p className="prd-descr">{product.descripcion.substr(0, 25)}</p>
        <p className="prd-descr">{product.descripcion.substr(25, 25)}</p>
        {len_dscr <= 25 ? <p className="prd-descr2">.</p> : null}
      </Card>
    </Col>
  );
}
