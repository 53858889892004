const initialProps = {
  moveCash: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_MOVE_CASH":
      return {
        ...state,
        moveCash: [...state.moveCash, action.payload],
      };
    case "UPDATE_MOVE_CASH":
      return {
        ...state,
        moveCash: action.payload,
      };
    default:
      return state;
  }
}
