export const createTicketDiscartedAction = (state) => {
  return {
    type: "CREATE_TICKET_DISCARTED",
    payload: state,
  };
};

export const updateTicketDiscartedAction = (state) => {
  return {
    type: "UPDATE_TICKET_DISCARTED",
    payload: state,
  };
};
