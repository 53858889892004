import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Select, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateGanaderiaAction } from "../../../actions/ganaderiaActions";

//Styles
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

export default function MaestroProveedoresForm(props) {
  const { record, closeModal, currentUser, varEffec, tipoProvee } = props;
  console.log(record);
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.trc_id);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setCurId(record.trc_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) => dispatch(updateGanaderiaAction(state));

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;
  const { Option } = Select;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendMaestro(
      currentUser,
      curId,
      "Proveedores",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.ganaderia);
      showNotification(
        "topRight",
        4,
        "GESTION DE PROVEEDORES",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE PROVEEDORES",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="proveedores"
      onFinish={guardarCambios}
      initialValues={{
        trc_nombre: record.trc_id > 0 ? record.trc_nombre : "",
        trc_contacto: record.trc_id > 0 ? record.trc_contacto : "",
        trc_direccion: record.trc_id > 0 ? record.trc_direccion : "",
        trc_telefono: record.trc_id > 0 ? record.trc_telefono : "",
        trc_celular: record.trc_id > 0 ? record.trc_celular : "",
        trc_email: record.trc_id > 0 ? record.trc_email : "",
        trc_tipo_prv: record.trc_id > 0 ? record.trc_tipo_prv : "",
        trc_activo: record.trc_id > 0 && record.trc_activo === 1 ? true : false,
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.trc_id > 0 ? "ACTUALIZAR PROVEEDOR" : "AGREGAR PROVEEDOR"}
        </Title>
      </Form.Item>
      <Form.Item
        name="trc_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ width: 250, textTransform: "uppercase" }}
          value={record.trc_id > 0 ? record.trc_contacto : ""}
        />
      </Form.Item>
      <Form.Item
        name="trc_contacto"
        label="Contacto"
        rules={[
          {
            required: true,
            message: "El nombre de contacto es obligatorio",
          },
        ]}
      >
        <Input
          style={{ width: 250, textTransform: "uppercase" }}
          value={record.trc_id > 0 ? record.trc_contacto : ""}
        />
      </Form.Item>
      <Form.Item
        name="trc_tipo_prv"
        label="Tipo"
        rules={[
          {
            required: true,
            message: "El tipo es obligatorio",
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          style={{ width: "100%" }}
          defaultValue={record.trc_tipo_prv}
          placeholder="Tipo"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {tipoProvee.map((tipospr) => (
            <Option key={tipospr.id} value={tipospr.id}>
              {tipospr.tpr_nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="trc_direccion"
        label="Direccion"
        rules={[
          {
            required: true,
            message: "La dirección es obligatoria",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.trc_id > 0 ? record.trc_direccion : ""}
        />
      </Form.Item>
      <Form.Item
        name="trc_telefono"
        label="Teléfono"
        rules={[
          {
            required: true,
            message: "El Teléfono es obligatorio",
          },
        ]}
      >
        <Input
          style={{ width: 250, textTransform: "uppercase" }}
          value={record.trc_id > 0 ? record.trc_telefono : ""}
        />
      </Form.Item>
      <Form.Item
        name="trc_celular"
        label="Celular"
        rules={[
          {
            required: true,
            message: "El celuar es obligatorio",
          },
        ]}
      >
        <Input
          style={{ width: 250, textTransform: "uppercase" }}
          value={record.trc_id > 0 ? record.trc_celular : ""}
        />
      </Form.Item>
      <Form.Item
        name="trc_email"
        label="Email"
        rules={[
          {
            required: true,
            type: "email",
            message: "La dirección de email es obligatoria",
          },
        ]}
      >
        <Input
          style={{ width: 250 }}
          value={record.trc_id > 0 ? record.trc_email : ""}
        />
      </Form.Item>
      <Form.Item name="trc_activo" label="Activo">
        <Switch
          checkedChildren="SI"
          unCheckedChildren="NO"
          defaultChecked={record.trc_activo === 1 ? true : false}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
