export const createTicketFourAction = (state) => {
  return {
    type: "CREATE_TICKET_FOUR",
    payload: state,
  };
};

export const updateTicketFourAction = (state) => {
  return {
    type: "UPDATE_TICKET_FOUR",
    payload: state,
  };
};
