const initialProps = {
  ticketFive: {
    id: "0",
    totalTicket: 0,
    cliente: "",
  },
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_FIVE":
      return {
        ...state,
        ticketFive: action.payload,
      };
    case "UPDATE_TICKET_FIVE":
      return {
        ...state,
        ticketFive: action.payload,
      };
    default:
      return state;
  }
}
