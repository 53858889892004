const initialProps = {
  ticketOneDetalle: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_ONE_DETALLE":
      return {
        ...state,
        ticketOneDetalle: [...state.ticketOneDetalle, action.payload],
      };
    case "UPDATE_TICKET_ONE_DETALLE":
      return {
        ...state,
        ticketOneDetalle: action.payload,
      };
    case "REMOVE_TICKET_ONE_DETALLE":
      return {
        ...state,
        ticketOneDetalle: action.payload,
      };
    default:
      return state;
  }
}
