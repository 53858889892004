import React from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Space,
  Badge,
  Dropdown,
  List,
  Typography,
  Button,
  Modal as ModalB,
} from "antd";
import Icon from "@mdi/react";
import {
  mdiLogout,
  mdiBasketOutline,
  mdiBasketOffOutline,
  mdiCloudDownloadOutline,
  mdiCloudUploadOutline,
  mdiCashPlus,
  mdiCashMinus,
  mdiRefresh,
} from "@mdi/js";

import {
  BellOutlined,
  MenuOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

import moment from "moment";

import { ExclamationCircleOutlined } from "@ant-design/icons";

//Firebase
import firebase from "../../../utils/Firebase";
import "firebase/auth";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateUserAction } from "../../../actions/userActions";
import { createBackupAction } from "../../../actions/backupActions";
import { updateLoguedAction } from "../../../actions/isLoguedActions";
import { updateInventoryAction } from "../../../actions/inventoryActions";
import { updateShiftsAction } from "../../../actions/Pos/shiftsListActions";
import { updateTicketTempAction } from "../../../actions/Pos/ticketsTempActions";
import { updateTicketTempDetalleAction } from "../../../actions/Pos/ticketsTempDetalleActions";
import { updateTicketDiscartedAction } from "../../../actions/Pos/ticketsDiscartedActions";
import { updateTicketDiscartedDetalleAction } from "../../../actions/Pos/ticketsDiscartedDetalleActions";
import { updateTicketsAction } from "../../../actions/Pos/ticketsActions";
import { updateTicketsDetalleAction } from "../../../actions/Pos/ticketsDetalleActions";
import { updateMoveCashAction } from "../../../actions/Pos/moveCashActions";

//Funciones propias
import { downloadDataPos, uploadDataPos } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";

//Imaganes y logos
import logo_title from "../../../assets/img/logo-menu.png";
import { colorPrm } from "../../../constants";

//Estilos
import "./Header.scss";
import curStyle from "../../../css/GlobalCmp.module.css";

export default function Header(props) {
  const {
    setIsLoading,
    currentUser,
    userOptions,
    currentShift,
    openModal,
    openDrawer,
    curTicket,
    myNumber,
    curCurrency,
    discartedDetalle,
    ticketsDefDetalle,
    ticketsDiscarted,
    ticketsDef,
    shiftsList,
    ticketsTemp,
    gestionCash,
    updateEnviroment,
    setListProducts,
    downData,
    ticketsTurno,
    ticketsTurnoDet,
    resumenTurno,
  } = props;

  const history = useHistory();

  //Ejecutar dispatch SEGUN BECESIDADES
  const dispatch = useDispatch();
  const createBackup = (state) => dispatch(createBackupAction(state));
  const updateData = (state) => dispatch(updateUserAction(state));
  const updateLogued = (state) => dispatch(updateLoguedAction(state));
  const updateInventory = (state) => dispatch(updateInventoryAction(state));
  const updateShifts = (state) => dispatch(updateShiftsAction(state));
  const clearTicketTemp = (state) => dispatch(updateTicketTempAction(state));
  const clearTicketTempDetalle = (state) =>
    dispatch(updateTicketTempDetalleAction(state));
  const clearTicketDisc = (state) =>
    dispatch(updateTicketDiscartedAction(state));
  const clearTicketDiscDetalle = (state) =>
    dispatch(updateTicketDiscartedDetalleAction(state));
  const clearTicketsDef = (state) => dispatch(updateTicketsAction(state));
  const clearTicketsDefDetalle = (state) =>
    dispatch(updateTicketsDetalleAction(state));
  const clearGestion = (state) => dispatch(updateMoveCashAction(state));

  const { Title } = Typography;
  const { confirm } = ModalB;

  const logOut = async () => {
    confirm({
      title: "CERRAR SESION",
      icon: <ExclamationCircleOutlined />,
      okText: "Cerrar Sesión",
      cancelText: "Cancelar",
      content: "Desea salir de 2bc ERP?",
      onOk() {
        firebase.auth().signOut();
        updateData({
          id: 0,
          nombre: "DESCONECTADO",
          pagina: "",
        });
        updateLogued(false);
        history.push("/");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const clearEfect = async () => {
    setIsLoading(true);
    clearGestion([]);
    showNotification(
      "topRight",
      4,
      "LIMPIAR EFECTIVO",
      "DATOS ELIMINADOS CON EXITO"
    );
    /*updateShifts(tickets);
    clearTicketTemp(tickets);
    clearTicketTempDetalle(tickets);
    clearTicketDisc(tickets);
    clearTicketDiscDetalle(tickets);
    clearTicketsDef(tickets);
    clearTicketsDefDetalle(tickets);
    clearGestion(tickets);
    return false;*/
    setIsLoading(false);
  };

  const upData = async () => {
    if (currentShift.user > 0) {
      showNotification(
        "topRight",
        1,
        "SICRONIZAR DATOS",
        "HAY UN TURNO ABIERTO... DEBE CERRARLO ANTES"
      );
      return false;
    }
    setIsLoading(true);
    const savedGestion = {
      fechaBackup: moment(),
      ticketsDef: ticketsDef,
      ticketsDef_items: ticketsDefDetalle,
      ticketsDsc: ticketsDiscarted,
      ticketsDsc_items: discartedDetalle,
      shiftsList: shiftsList,
      movesCash: gestionCash,
      ticketsTurno: ticketsTurno,
      ticketsTurnoDet: ticketsTurnoDet,
      resumenTurno: resumenTurno,
    };
    //Hago un backuo de la data
    createBackup([]);
    //Subo los datos
    let reLoad = await uploadDataPos(
      currentUser,
      savedGestion,
      currentShift.id
    );
    if (reLoad) {
      //updateInventory(reLoad.inventario);
      updateShifts([]);
      clearTicketTemp([]);
      clearTicketTempDetalle([]);
      clearTicketDisc([]);
      clearTicketDiscDetalle([]);
      clearTicketsDef([]);
      clearTicketsDefDetalle([]);
      clearGestion([]);
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS CARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  const shiftClose = async () => {
    showNotification(
      "topRight",
      1,
      "CERRAR TURNO",
      "TIENE TICKET(S) EN ESPERA... DEBE FACTUAR O DESCARTAR"
    );
    return false;
  };

  const shiftOpen = async () => {
    showNotification(
      "topRight",
      1,
      "ABRIR TURNO",
      "HAY DATOS PENDIENTES DE SINCRONIZAR... SINCRONICE ANTES DE ABRIR"
    );
    return false;
  };

  const opciones = [
    {
      key: "1",
      title: "Descargar Datos",
      icon: (
        <Icon path={mdiCloudDownloadOutline} size={"26px"} color={colorPrm} />
      ),
      onClick: () => downData(),
    },
    {
      key: "2",
      title: "Ingresos de Efectivo",
      icon: <Icon path={mdiCashPlus} size={"26px"} color={colorPrm} />,
      onClick: () => openModal("Ingresos", 7, "600px"),
    },
    {
      key: "3",
      title: "Salidas de Eefectivo",
      icon: <Icon path={mdiCashMinus} size={"26px"} color={colorPrm} />,
      onClick: () => openModal("Salidas", 7, "600px"),
    },
    {
      key: "4",
      title: currentShift.user === 0 ? "Abrir Turno" : "Cerrar Turno",
      icon:
        currentShift.user === 0 ? (
          <Icon path={mdiBasketOutline} size={"26px"} color={colorPrm} />
        ) : (
          <Icon path={mdiBasketOffOutline} size={"26px"} color={colorPrm} />
        ),
      onClick: () =>
        currentShift.user === 0
          ? ticketsDef.length > 0
            ? shiftOpen()
            : openModal("Abrir Turno", 1, "400px")
          : ticketsTemp.length > 0
          ? shiftClose()
          : openModal("Cerrar Turno", 2, "700px"),
    },
    {
      key: "7",
      title: "Limpiar Efectivo",
      icon: <Icon path={mdiRefresh} size={"26px"} color={colorPrm} />,
      onClick: () => clearEfect(),
    },
    {
      key: "5",
      title: "Subir Datos",
      icon: (
        <Icon path={mdiCloudUploadOutline} size={"26px"} color={colorPrm} />
      ),
      onClick: () => upData(),
    },
    {
      key: "6",
      title: "Cerrar Sesion",
      icon: <Icon path={mdiLogout} size={"26px"} color={colorPrm} />,
      onClick: () => logOut(),
    },
  ];

  const ShowAlerts = (
    <form
      style={{
        backgroundColor: "#ffffff",
        marginTop: "5px",
        width: "350px",
        padding: "5px",
      }}
    >
      <div>
        <List
          style={{ marginTop: 5 }}
          itemLayout="horizontal"
          dataSource={opciones}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: 3, marginTop: 3, padding: 2 }}>
              <List.Item.Meta
                avatar={item.icon}
                title={
                  <Title
                    level={4}
                    style={{
                      padding: 2,
                      margin: 2,
                      fontWeight: 300,
                      fontSize: 18,
                    }}
                  >
                    {item.title}
                  </Title>
                }
                onClick={item.onClick}
              />
            </List.Item>
          )}
        />
      </div>
    </form>
  );

  return (
    <>
      <Row className="main-header">
        <span style={{ left: "10px", position: "absolute", top: 10 }}>
          {userOptions.length > 0 ? (
            <Space size={"middle"}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<MenuOutlined style={{ color: colorPrm }} />}
              />
              <img src={logo_title} alt="Imagen" className="img-logo-title" />
            </Space>
          ) : (
            <img src={logo_title} alt="Imagen" className="img-logo-title" />
          )}
        </span>
        <span style={{ textAlign: "center", top: 10 }}>
          <Title level={4} style={{ color: colorPrm, marginTop: 10 }}>
            {"REGISTRAR VENTAS"}
          </Title>
        </span>
        <span
          className="li-options"
          style={{
            textAlign: "right",
            right: "5px",
            top: 10,
            position: "absolute",
            width: "750px",
          }}
        >
          <Space size={"middle"}>
            <Badge count={5} style={{ backgroundColor: "orange" }}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<BellOutlined style={{ color: colorPrm }} />}
              />
            </Badge>
            <Badge count={5} style={{ backgroundColor: "#108ee9" }}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<MailOutlined style={{ color: colorPrm, size: 24 }} />}
              />
            </Badge>
            {currentUser.nombre}
            <Dropdown
              overlay={ShowAlerts}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <Button
                type="primary"
                className={curStyle.btnSecundary}
                shape="circle"
                icon={<UserOutlined />}
              />
            </Dropdown>
            {currentUser.menu_r ? (
              <Button
                type="primary"
                className={curStyle.btnWhite}
                onClick={() => openDrawer(2)}
                shape="circle"
                icon={<MenuOutlined style={{ color: "#52c41a", size: 24 }} />}
              />
            ) : null}
          </Space>
        </span>
      </Row>
    </>
  );
}
