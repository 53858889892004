export const createTicketsDetalleAction = (state) => {
  return {
    type: "CREATE_TICKETS_DETALLE",
    payload: state,
  };
};

export const updateTicketsDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKETS_DETALLE",
    payload: state,
  };
};
