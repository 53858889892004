import React, { useState, useEffect } from "react";
import { List, Divider, Spin, Empty, message, Typography } from "antd";
import InfiniteScroll from "react-infinite-scroller";

// Importacion de constantes
import { colorSec, colorPrm } from "../../../constants";
import curStyle from "../../../css/GlobalCmp.module.css";

// Redux
import { useSelector } from "react-redux";

export default function DetalleTicket(props) {
  const {
    myNumber,
    validateRows,
    deleteRows,
    updateRows,
    updateCurTicket,
    curCurrency,
    setDrwDetalle,
    showDetalle,
  } = props;
  const [curItem, setCurItem] = useState({});
  const [prcView, setPrcView] = useState(true);

  //useSelector para acceder al store
  const detalleTicket = useSelector(
    (state) => state.detalleTicket.detalleTicket
  );

  const [cartDeta, setCartDeta] = useState([]);
  const [cargando, setCargando] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // para mostrar el scroball cuando supere los 14 productos seleccionads
  const handleInfiniteOnLoad = () => {
    setLoading(true);
    if (showDetalle.length > 2) {
      message.warning("Infinite List loaded all");
      setHasMore(false);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  //Actualizar el carrito
  useEffect(() => {
    setCartDeta(detalleTicket);
  }, [detalleTicket]);

  const { Title } = Typography;

  return (
    <div className="cart" style={{ minHeight: window.innerHeight - 170 }}>
      <div
        style={{
          padding: "8 24 0 0",
          minHeight: window.innerHeight - 200,
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {"DETALLE TICKET"}
          </Title>
        </div>
        <Divider style={{ margin: 3, paddingTop: 3, padding: 3 }} />
        {Object.keys(showDetalle).length !== 0 ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <List
              style={{ marginTop: -10 }}
              itemLayout="horizontal"
              loading={cargando}
              dataSource={cartDeta}
              renderItem={(item) => (
                <List.Item
                  style={{
                    marginTop: 1,
                    marginBottom: 1,
                    padding: 3,
                    height: 70,
                  }}
                >
                  <List.Item.Meta
                    title={
                      <span
                        style={{
                          color: colorPrm,
                          fontWeight: "600",
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            margin: 0,
                            padding: 0,
                            color: colorSec,
                            fontSize: 16,
                          }}
                        >
                          {item.codigo + " - " + item.descripcion}
                        </Title>
                      </span>
                    }
                    description={
                      <div>
                        <span
                          style={{
                            color: colorPrm,
                            cursor: "pointer",
                            fontWeight: "600",
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              margin: 0,
                              padding: 0,
                              color: colorPrm,
                              fontSize: 16,
                            }}
                          >
                            {"Precio: " +
                              curCurrency.simbolo +
                              myNumber(1, item.precio) +
                              " | " +
                              "Cantidad: " +
                              item.cantidad +
                              " | SUBTOTAL: " +
                              curCurrency.simbolo +
                              myNumber(1, item.cantidad * item.precio)}
                          </Title>
                        </span>
                      </div>
                    }
                  />
                </List.Item>
              )}
            >
              {loading && hasMore && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 40,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Spin />
                </div>
              )}
            </List>
            <Divider style={{ marginTop: 3, paddingTop: 3, padding: 3 }} />
          </InfiniteScroll>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
              paddingLeft: 8,
              color: colorSec,
              minHeight: window.innerHeight - 270,
            }}
            description={"SIN ITEMS PENDIENTES"}
          />
        )}
      </div>
    </div>
  );
}
