const initialProps = {
  ticketsTempDetalle: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_TEMP_DETALLE":
      return {
        ...state,
        ticketsTempDetalle: action.payload,
      };
    case "UPDATE_TICKET_TEMP_DETALLE":
      return {
        ...state,
        ticketsTempDetalle: action.payload,
      };
    default:
      return state;
  }
}
