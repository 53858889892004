export const createTicketAction = (state) => {
  return {
    type: "CREATE_TICKET",
    payload: state,
  };
};

export const updateTicketAction = (state) => {
  return {
    type: "UPDATE_TICKET",
    payload: state,
  };
};
