export const createTicketOneAction = (state) => {
  return {
    type: "CREATE_TICKET_ONE",
    payload: state,
  };
};

export const updateTicketOneAction = (state) => {
  return {
    type: "UPDATE_TICKET_ONE",
    payload: state,
  };
};
