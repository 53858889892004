export const createTicketThreeDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_THREE_DETALLE",
    payload: state,
  };
};

export const updateTicketThreeDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_THREE_DETALLE",
    payload: state,
  };
};

export const removeTicketThreeDetalleAction = (state) => {
  return {
    type: "REMOVE_TICKET_THREE_DETALLE",
    payload: state,
  };
};
