export const createTicketFourDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_FOUR_DETALLE",
    payload: state,
  };
};

export const updateTicketFourDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_FOUR_DETALLE",
    payload: state,
  };
};

export const removeTicketFourDetalleAction = (state) => {
  return {
    type: "REMOVE_TICKET_FOUR_DETALLE",
    payload: state,
  };
};
