import React from "react";
import { Modal } from "antd";

//Styles
import "./Modal.scss";

export default function ShowModal(props) {
  const { isVisibleModal, closeModal, widthModal, children } = props;

  return (
    <Modal
      visible={isVisibleModal}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      width={widthModal}
      closable={false}
      footer={false}
      destroyOnClose={true}
    >
      <div>{children}</div>
    </Modal>
  );
}
