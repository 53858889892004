import React from "react";
import { Row, Col, Spin } from "antd";
import logo from "../../assets/img/logo-grey-small.png";

import "./LoadGrey.scss";

export default function Loading() {
  return (
    <div
      className="load-grey"
      style={{
        height: "100%",
        backgroundSize: "cover",
        width: "100vw",
        marginTop: 20,
      }}
    >
      <Row>
        <Col span={24} style={{ alignItems: "center" }}>
          <img
            src={logo}
            alt="Imagen"
            style={{ marginBottom: "20px", width: window.innerWidth * 0.3 }}
          />
        </Col>
      </Row>
    </div>
  );
}
