const initialProps = {
  tickets: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKETS":
      return {
        ...state,
        tickets: [...state.tickets, action.payload],
      };
    case "UPDATE_TICKETS":
      return {
        ...state,
        tickets: action.payload,
      };
    default:
      return state;
  }
}
