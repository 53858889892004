const initialProps = {
  ganaderiaEnv: {},
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "UPDATE_STATE_GANADERIA":
      return {
        ...state,
        ganaderiaEnv: action.payload,
      };
    default:
      return state;
  }
}
