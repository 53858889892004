export const createTicketTwoAction = (state) => {
  return {
    type: "CREATE_TICKET_TWO",
    payload: state,
  };
};

export const updateTicketTwoAction = (state) => {
  return {
    type: "UPDATE_TICKET_TWO",
    payload: state,
  };
};
