import React, { useState, useEffect } from "react";
import { Input, Button, Divider, Typography, Popconfirm, message } from "antd";

//Iconos y constantes
import { BarcodeOutlined } from "@ant-design/icons";

//Componentes Personalizados
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import POS from "../../../utils/PosService";

//Estilos
import "./UpdateBarCode.scss";

export default function UpdateBarCode(props) {
  const {
    currentUser,
    curProduct,
    closeModal,
    varEffec,
    setListProducts,
    updateInventory,
    setLoadInventory,
    updateEnviroment,
    currentShift,
  } = props;
  const [tituloModal, setTituloModal] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [oldBCode, setOldBCode] = useState("");
  const [barCode, setBarCode] = useState("");

  const { Title } = Typography;

  var titulo = "CODIGO: " + curProduct.barcode;

  useEffect(() => {
    setTituloModal(titulo);
    setBarCode(curProduct.barcode);
    setOldBCode(curProduct.barcode);
  }, [varEffec]);

  const onChangeBar = (e) => {
    let codigoB = e.target.value;
    if (codigoB.length > 0) {
      setBarCode(codigoB.toUpperCase());
    }
  };

  const actualizaBCode = async (tipo) => {
    if (Number.parseInt(barCode.length) === 0 || barCode === oldBCode) {
      showNotification(
        "topRight",
        2,
        "CODIGO DE BARRAS",
        "Valores incorrectos... verifique!"
      );
      return false;
    }
    setLoadingButton(true);
    let val_codbar = await POS.updateCodBar(
      currentUser,
      curProduct.prd_id,
      barCode,
      currentShift.id
    );
    if (Number.parseInt(val_codbar.type) > 0) {
      //Se actualiza el inventario
      message.success("CODIGO ACTUALIZADO CORRECATMENTE", 1);
      updateInventory(val_codbar.datos.inventario);
      updateEnviroment(val_codbar.datos.entorno);
      if (tipo === 2) {
        setListProducts([]);
        setLoadInventory(new Date());
      }
    } else {
      showNotification("topRight", 2, "CODIGO DE BARRAS", val_codbar.message);
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <>
      <Title
        level={3}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: CST.colorSec,
        }}
      >
        {tituloModal}
      </Title>

      <Title
        level={4}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: "#6A3E98",
        }}
      >
        NUEVO CODIGO
      </Title>

      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <Input
        size="large"
        defaultValue={curProduct.barcode}
        value={barCode}
        onChange={onChangeBar}
        style={{ width: "100%" }}
        maxLength={13}
      />
      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <Popconfirm
        placement="top"
        title="¿ Actualizar el codigo ?"
        onConfirm={() => actualizaBCode(1)}
        onCancel={() => actualizaBCode(2)}
        okText="Actualizar"
        cancelText="Refrescar"
        okType="primary"
        icon={<BarcodeOutlined style={{ color: CST.colorPrm }} />}
      >
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          shape="round"
          block
          loading={loadingButton}
        >
          <BarcodeOutlined /> ACTUALIZAR
        </Button>
      </Popconfirm>
    </>
  );
}
