import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, Typography } from "antd";
import Highlighter from "react-highlight-words";

//Iconos y constantes
import { SearchOutlined } from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

//Estilos
import "./Inventario.scss";
import { myNumber } from "../../../utils/ArrayFunctions";

export default function Inventario(props) {
  const { setIsLoading, nombreMes, gstResumen } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [detalleOffer, setDetalleOffer] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalText, setModalText] = useState("Desea eliminar la oferta?");
  const [listAnimales, setListAnimales] = useState([]);

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getInventario(currentUser);
      if (datos.type && datos.type > 0) {
        setListAnimales(datos.listado);
      } else {
        showNotification(2, "ERROR DE CONEXION", "Intente mas Tarde");
      }
      setIsLoading(false);
    })();
  }, [gstResumen]);

  //const { confirm } = Modal;
  const { Title } = Typography;

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleOffer(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      width: "8%",
    },
    {
      title: "RAZA",
      dataIndex: "rza_nombre",
      key: "rza_nombre",
      width: "8%",
    },
    {
      title: "CRUCE",
      dataIndex: "crc_nombre",
      key: "crc_nombre",
      width: "8%",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      width: "2%",
      render: (text, row, index) => {
        if (index === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "CATEGORIA",
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
      width: "12%",
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      width: "2%",
      render: (text, row, index) => {
        return myNumber(
          1,
          Number.parseInt(row.anm_dias) + Number.parseInt(row.dias)
        );
      },
    },
    {
      title: "COSTO",
      dataIndex: "anm_costo",
      key: "anm_costo",
      width: "2%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(
          1,
          Number.parseFloat(row.anm_costo) *
            Number.parseFloat(row.anm_peso_neto) +
            Number.parseFloat(row.anm_comision) +
            Number.parseFloat(row.anm_transporte)
        );
      },
    },
    {
      title: "PESO",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "2%",
      align: "right",
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => openModal(record, 1, "70%px")}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listAnimales}
        style={{ padding: 3, marginTop: 5 }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? null : null}
      </ModalApp>
    </div>
  );
}
