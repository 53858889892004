const initialProps = {
  ticketsDiscarted: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_DISCARTED":
      return {
        ...state,
        ticketsDiscarted: [...state.ticketsDiscarted, action.payload],
      };
    case "UPDATE_TICKET_DISCARTED":
      return {
        ...state,
        ticketsDiscarted: action.payload,
      };
    default:
      return state;
  }
}
