import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Drawer,
  Popconfirm,
  Select,
  Typography,
  message,
  Divider,
  Tooltip,
  Button,
} from "antd";

//Creaciones de ID y Fecha
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import briefcase from "../../assets/icons/briefcase-account-outline.svg";
import download from "../../assets/icons/cloud-download-outline.svg";
import cashplus from "../../assets/icons/cash-plus.svg";
import backups from "../../assets/icons/folder-upload-outline.svg";
import prepayed from "../../assets/icons/account-cash-outline.svg";
import itemsSold from "../../assets/icons/playlist-edit.svg";
import calendar from "../../assets/icons/calendar-clock-outline.svg";

//Iconos
import {
  DeleteOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

//Componentes adicionales
import { TitlePage } from "../../components/TitlePage";
import MainHeader from "../../components/Pos/Header";
import ModalApp from "../../components/Modal";
import OpenShift from "../../components/Pos/OpenShift";
import CloseShift from "../../components/Pos/CloseShift";
import MainFooter from "../../components/Footer";
import ProductsCatalogo from "../../components/Pos/ProductsCatalog";
import ProductDetails from "../../components/Pos/ProductDetails";
import TicketSearch from "../../components/Pos/TicketSearch";
import Cart from "../../components/Pos/Cart";
import TicketsShift from "../../components/Pos/TicketsShift";
import DetalleTicket from "../../components/Pos/DetalleTicket";
import CloseTicket from "../../components/Pos/CloseTicket";
import UpdateBarCode from "../../components/Pos/UpdateBarCode";
import GestionEfectivo from "../../components/Pos/GestionEfectivo";
import Debtors from "../../components/Pos/Debtors";
import Prepaied from "../../components/Pos/Prepaied";
import SoldItems from "../../components/Pos/SoldItems";

//Funciones y componentes propios
import { showNotification } from "../../utils/ShowNotification";
import { downloadDataPos, uploadBackUpPos } from "../../utils/ReloadEnv";
import POS from "../../utils/PosService";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";

// Actions de Redux
//Creacion de los 5 tickets simultaneos
import {
  createTicketOneAction,
  updateTicketOneAction,
} from "../../actions/Pos/ticketOneActions";
import {
  createTicketOneDetalleAction,
  updateTicketOneDetalleAction,
} from "../../actions/Pos/ticketOneDetalleActions";
import {
  createTicketTwoAction,
  updateTicketTwoAction,
} from "../../actions/Pos/ticketTwoActions";
import {
  createTicketTwoDetalleAction,
  updateTicketTwoDetalleAction,
} from "../../actions/Pos/ticketTwoDetalleActions";
import {
  createTicketThreeAction,
  updateTicketThreeAction,
} from "../../actions/Pos/ticketThreeActions";
import {
  createTicketThreeDetalleAction,
  updateTicketThreeDetalleAction,
} from "../../actions/Pos/ticketThreeDetalleActions";
import {
  createTicketFourAction,
  updateTicketFourAction,
} from "../../actions/Pos/ticketFourActions";
import {
  createTicketFourDetalleAction,
  updateTicketFourDetalleAction,
} from "../../actions/Pos/ticketFourDetalleActions";
import {
  createTicketFiveAction,
  updateTicketFiveAction,
} from "../../actions/Pos/ticketFiveActions";
import {
  createTicketFiveDetalleAction,
  updateTicketFiveDetalleAction,
} from "../../actions/Pos/ticketFiveDetalleActions";

//Actualizar ticket seleccionado
import { updateSelectedTicketAction } from "../../actions/Pos/selectedTicketActions";

//Actualizar entorno
import { updateEnviromentAction } from "../../actions/enviromentActions";

//Current Ticket Encabezado y Detalle
import { updateTicketAction } from "../../actions/Pos/curTicketActions";
import {
  createTicketDetalleAction,
  updateTicketDetalleAction,
} from "../../actions/Pos/curTicketDetalleActions";

//Producto actual para mostrar detalle
import { updateCurProductAction } from "../../actions/Pos/curProductActions";

//Lista de precios actual
import { updatePriceListAction } from "../../actions/priceListActions";

//Tickets descartados encabezado y detalle
import { createTicketDiscartedAction } from "../../actions/Pos/ticketsDiscartedActions";
import { createTicketDiscartedDetalleAction } from "../../actions/Pos/ticketsDiscartedDetalleActions";

//Tickets procesados, encabezado y detalle
import {
  createTicketsAction,
  updateTicketsAction,
} from "../../actions/Pos/ticketsActions";
import {
  createTicketsDetalleAction,
  updateTicketsDetalleAction,
} from "../../actions/Pos/ticketsDetalleActions";

//Inventario
import { updateInventoryAction } from "../../actions/inventoryActions";

//Entradas y salidas de dinero
import { updateMoveCashAction } from "../../actions/Pos/moveCashActions";

import { updateDetalleTicketAction } from "../../actions/Pos/detalleTicketActions";

//Funciones para administrar el carrito
import {
  myNumber,
  validaTicketDet,
  updateTicketDet,
  deleteTicketDet,
  totalTicketDet,
  removeHeaderTmp,
  deleteTicketTmpDet,
  addTicketTempDet,
  updateTicketDetPrecio,
  updateTicketDetLista,
  filtrarArray,
  updateEstadoTicketDet,
} from "../../utils/ArrayFunctions";

//Estilos
import "./invoice.scss";
import curStyle from "../../css/GlobalCmp.module.css";
import { colorSec, colorPrm } from "../../constants";

export default function Facturar(props) {
  const { setIsLoading } = props;
  const { Footer } = Layout;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("700px");
  const [titleModal, setTitleModal] = useState();
  const [isDrawer, setIsDrawer] = useState(false);
  const [drwDetalle, setDrwDetalle] = useState(1);
  const [cliente, setCliente] = useState("");

  const { Content, Sider } = Layout;

  //////////// NUEVAS FUNCIONES DEL DISPATCH /////////////
  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch();

  //Actuliazar entorno
  const updateEnviroment = (state) => dispatch(updateEnviromentAction(state));

  //Creacion de los 5 tiquetes simultaneos
  const createOne = (state) => dispatch(createTicketOneAction(state));
  const createTwo = (state) => dispatch(createTicketTwoAction(state));
  const createThree = (state) => dispatch(createTicketThreeAction(state));
  const createFour = (state) => dispatch(createTicketFourAction(state));
  const createFive = (state) => dispatch(createTicketFiveAction(state));
  const createOneDet = (state) => dispatch(createTicketOneDetalleAction(state));
  const createTwoDet = (state) => dispatch(createTicketTwoDetalleAction(state));
  const createThreeDet = (state) =>
    dispatch(createTicketThreeDetalleAction(state));
  const createFourDet = (state) =>
    dispatch(createTicketFourDetalleAction(state));
  const createFiveDet = (state) =>
    dispatch(createTicketFiveDetalleAction(state));

  //Actualizacion encabezado 5 Tickets
  const updateOne = (state) => dispatch(updateTicketOneAction(state));
  const updateTwo = (state) => dispatch(updateTicketTwoAction(state));
  const updateThree = (state) => dispatch(updateTicketThreeAction(state));
  const updateFour = (state) => dispatch(updateTicketFourAction(state));
  const updateFive = (state) => dispatch(updateTicketFiveAction(state));
  const updateOneDet = (state) => dispatch(updateTicketOneDetalleAction(state));
  const updateTwoDet = (state) => dispatch(updateTicketTwoDetalleAction(state));
  const updateThreeDet = (state) =>
    dispatch(updateTicketThreeDetalleAction(state));
  const updateFourDet = (state) =>
    dispatch(updateTicketFourDetalleAction(state));
  const updateFiveDet = (state) =>
    dispatch(updateTicketFiveDetalleAction(state));

  //Update selected ticket
  const changeTicket = (state) => dispatch(updateSelectedTicketAction(state));

  //Actualizar encabezado del curTicket
  const updateTicket = (state) => dispatch(updateTicketAction(state));

  //Actualizar el detalle del curTicket
  const updateDetalle = (productos) =>
    dispatch(updateTicketDetalleAction(productos));

  ////////////// FIN NUEVO DISPATCH ///////////////////

  //Actualizar el inventario
  const updateInventory = (state) => dispatch(updateInventoryAction(state));

  //Agregar producto al carrito curTicket
  const addProduct = (producto) =>
    dispatch(createTicketDetalleAction(producto));

  //Actualizar el current Producto para mostrar detalle
  const updateCurProduct = (record) => dispatch(updateCurProductAction(record));

  //Se limpia el detalle del currentTicket
  const clearDetalleTicket = (state) =>
    dispatch(updateTicketDetalleAction(state));

  //Se agrega el nuevo encabezado a ticketsDiscarted
  const createTicketDisc = (state) =>
    dispatch(createTicketDiscartedAction(state));

  //Se agrega el detalle a ticketsDiscarted
  const createTicketDiscDetalle = (state) =>
    dispatch(createTicketDiscartedDetalleAction(state));

  //Se actualiza la lista de precios
  const updateListaPrc = (state) => dispatch(updatePriceListAction(state));

  //Se agrega el nuevo encabezado a tickets procesados
  const createTicketDef = (state) => dispatch(createTicketsAction(state));
  const updateTicketDef = (state) => dispatch(updateTicketsAction(state));

  //Se agrega el detalle a tickets procesados
  const createTicketDefDetalle = (state) =>
    dispatch(createTicketsDetalleAction(state));
  const updateTicketDefDetalle = (state) =>
    dispatch(updateTicketsDetalleAction(state));

  //Actualizar estado de movimientos de efectivo
  const updateGestion = (state) => dispatch(updateMoveCashAction(state));

  //Actualizar detalle del ticket seleccionado
  const updateDetaTicket = (state) =>
    dispatch(updateDetalleTicketAction(state));

  ////////////// NUEVOS SELCTORES AL STORE ////////////
  //Validacion del logueo de usuario
  const isLogued = useSelector((state) => state.isLogued.isLogued);

  //Datos generales del usuario
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  //Opciones de usuario menu izquierdo
  const userOptions = useSelector(
    (state) => state.currentUser.currentUser.user_options
  );

  //Opciones de usuario menu izquierdo
  const userModules = useSelector(
    (state) => state.currentUser.currentUser.user_modules
  );

  //Validacion del turno actual
  const currentShift = useSelector((state) => state.shift.shift);

  //Ticket actualmente selccionado
  const selectedTck = useSelector(
    (state) => state.selectedTicket.selectedTicket
  );

  //Selector de Tickets Simultaneo
  const ticketOne = useSelector((state) => state.ticketOne.ticketOne);
  const ticketTwo = useSelector((state) => state.ticketTwo.ticketTwo);
  const ticketThree = useSelector((state) => state.ticketThree.ticketThree);
  const ticketFour = useSelector((state) => state.ticketFour.ticketFour);
  const ticketFive = useSelector((state) => state.ticketFive.ticketFive);
  const ticketOneDet = useSelector(
    (state) => state.ticketOneDetalle.ticketOneDetalle
  );
  const ticketTwoDet = useSelector(
    (state) => state.ticketTwoDetalle.ticketTwoDetalle
  );
  const ticketThreeDet = useSelector(
    (state) => state.ticketThreeDetalle.ticketThreeDetalle
  );
  const ticketFourDet = useSelector(
    (state) => state.ticketFourDetalle.ticketFourDetalle
  );
  const ticketFiveDet = useSelector(
    (state) => state.ticketFiveDetalle.ticketFiveDetalle
  );

  //Ticket actual
  const curTicket = useSelector((state) => state.curTicket.curTicket);

  //Detalle del ticket actual
  const detalleCurTicket = useSelector(
    (state) => state.curTicketDetalle.curTicketDetalle
  );

  ////////// FIN NUEVO SELECTOR ///////////

  //Validacion del turno actual
  const shiftsList = useSelector((state) => state.shiftsList.shiftsList);

  //Listado de productos
  const stockProductos = useSelector((state) => state.curStock.curStock);

  //Inventario inicial
  const initInventory = useSelector(
    (state) => state.enviroment.enviroment.productos
  );

  //Detalle de los tickets en espera
  const ticketsTempDetalle = useSelector(
    (state) => state.ticketsTempDetalle.ticketsTempDetalle
  );

  //Encabezados de los tickets en espera
  const ticketsTemp = useSelector((state) => state.ticketsTemp.ticketsTemp);

  //Detalle de los tickets descartados
  const discartedDetalle = useSelector(
    (state) => state.discartedDetalle.discartedDetalle
  );

  //Detalle de los tickets definitivos
  const ticketsDefDetalle = useSelector(
    (state) => state.ticketsDetalle.ticketsDetalle
  );
  const ticketsTurnoDet = useSelector(
    (state) => state.enviroment.enviroment.tickets_turno_det
  );

  //Encabezados de los tickets Descartados
  const ticketsDiscarted = useSelector(
    (state) => state.ticketsDiscarted.ticketsDiscarted
  );

  //Encabezados de los tickets Definitivos
  const ticketsDef = useSelector((state) => state.tickets.tickets);
  const ticketsTurno = useSelector(
    (state) => state.enviroment.enviroment.tickets_turno
  );
  const resumenTurno = useSelector(
    (state) => state.enviroment.enviroment.resumen_tickets
  );

  //Listado de clientes
  const customersList = useSelector(
    (state) => state.enviroment.enviroment.clientes
  );

  //Listados de precios
  const listasPrecios = useSelector(
    (state) => state.enviroment.enviroment.precios
  );

  //Monedas
  const curCurrency = useSelector(
    (state) => state.enviroment.enviroment.monedas
  );

  //Listado de clientes
  const priceList = useSelector((state) => state.priceList.priceList);

  //Produto actual para detalle o edicion de ticket
  const curProduct = useSelector(
    (state) => state.currentProduct.currentProduct
  );

  //Detalle a mostrar del ticket seleccionado
  const showDetalle = useSelector((state) => state.detalleTicket.detalleTicket);

  //Entradas y salidas de efectivo
  const gestionCash = useSelector((state) => state.moveCash.moveCash);

  //Backups registrados en Local Storage
  const curBackUps = useSelector((state) => state.backups.backups);

  //States y Setters para lista de productos
  const [listProducts, setListProducts] = useState([]);
  const [dataPro, setDataPro] = useState(stockProductos);
  const [searchProducto, setSearchProducto] = useState("");
  const [loadInventory, setLoadInventory] = useState(moment());

  useEffect(() => {
    (async () => {
      updateInventory(initInventory);
      setDataPro(initInventory);
    })();
  }, [loadInventory]);

  //Defibiciones adicioneles Antd
  const { Option } = Select;
  const { Title } = Typography;

  ///////// FUNCIONES PARA GESTIONAR LA VENTA //////
  //Record puede ser el producto o el registro
  //en el carrito de compras
  const validateRows = async (record) => {
    //Valido que haya un turno abierto
    if (currentShift.user === 0) {
      showNotification(
        "topLeft",
        1,
        "AGREGAR ITEM",
        "NO HAY TURNO ABIERTO... Verifique!!"
      );
      return false;
    }
    //Funcion para validar si el producto ya esta en el carrito

    let curHeader = ticketSelect(1);
    //Actualizo encabezado de CurTicket
    updateTicket(curHeader);
    let curDetalle = ticketSelect(2);
    let estaEnCarrito = validaTicketDet(record.prd_id, curDetalle);
    if (Number.parseInt(estaEnCarrito) > 0) {
      //Actualizo las cantidades en el carrito
      if (Number.parseInt(estaEnCarrito) < Number.parseInt(record.disponible)) {
        let newDet = updateTicketDet(record.prd_id, curDetalle, 1, 0);
        //Actualizo detalle de CurTicket
        ticketUpdate(2, newDet);
        updateDetalle(newDet);
        message.success("Producto Actualizado", 1);
      } else {
        showNotification(
          "topLeft",
          2,
          "AGREGAR ITEM",
          "CANTIDAD NO DISPONIBLE"
        );
        return false;
      }
    } else {
      //Agrego el producto en el carrito
      let newItem = {
        id: curHeader.id,
        prd_id: record.prd_id,
        codigo: record.codigo,
        descripcion: record.descripcion,
        cantidad: 1,
        disponible: Number.parseInt(record.disponible),
        costo: Number.parseFloat(record.costo),
        precioz: Number.parseFloat(record.precioz),
        preciog: Number.parseFloat(record.preciog),
        preciom: Number.parseFloat(record.preciom),
        precio:
          priceList === 1
            ? Number.parseFloat(record.preciog)
            : Number.parseFloat(record.preciom),
        estado: 0,
      };
      ticketCreate(newItem);
      addProduct(newItem);
      message.success("Producto Agregado", 1);
    }
  };

  //Funcion para actualizar el encabezado
  //del ticket actual
  const updateCurTicket = async (tipo, lista) => {
    if (tipo === 1) {
      //Se actualiza el total
      let newTotal = totalTicketDet(detalleCurTicket);
      const ticketUpd = {
        id: curTicket.id,
        fecha: curTicket.fecha,
        turno: curTicket.turno,
        user: curTicket.user,
        nombre: curTicket.nombre,
        cliente: curTicket.cliente,
        clt_id: curTicket.clt_id,
        totalTicket: newTotal,
        estado: curTicket.estado,
        lista: curTicket.lista,
        fecha_pago: curTicket.fecha_pago,
        nota: curTicket.nota,
        cobro: curTicket.cobro,
        cambio: curTicket.cambio,
        descuento: curTicket.descuento,
        ip_ticket: curTicket.ip_ticket,
      };
      updateTicket(ticketUpd);
      ticketUpdate(1, ticketUpd);
    } else {
      const ticketUpd = {
        id: curTicket.id,
        fecha: curTicket.fecha,
        turno: curTicket.turno,
        user: curTicket.user,
        nombre: curTicket.nombre,
        cliente: curTicket.cliente,
        clt_id: curTicket.clt_id,
        totalTicket: curTicket.totalTicket,
        estado: curTicket.estado,
        lista: lista,
        fecha_pago: curTicket.fecha_pago,
        nota: curTicket.nota,
        cobro: curTicket.cobro,
        cambio: curTicket.cambio,
        descuento: curTicket.descuento,
        ip_ticket: curTicket.ip_ticket,
      };
      updateTicket(ticketUpd);
      ticketUpdate(1, ticketUpd);
    }
  };

  //Funcion para actualizar la cantidad del
  //producto digitada
  const updateRows = async (record, newQty, tipoUpd) => {
    //Valido si la cantidad o el precio es mayor que 0
    if (Number.parseFloat(newQty) > 0) {
      //Actualizo la cantidad al valor digitado
      let newDet =
        tipoUpd === 1
          ? updateTicketDet(record.prd_id, detalleCurTicket, 3, newQty)
          : updateTicketDetPrecio(record.prd_id, detalleCurTicket, 3, newQty);
      updateDetalle(newDet);
      ticketUpdate(2, newDet);
      message.success("Producto Actualizado", 1);
    } else {
      //Retiro el producto del carrito
      let newDet = deleteTicketDet(record.prd_id, detalleCurTicket);
      updateDetalle(newDet);
      ticketUpdate(2, newDet);
      message.success("Producto Eliminado", 1);
    }
  };

  //Funcion para disminuir la cantidad del
  //producto en el detalle del carrito
  const deleteRows = async (record, tipo) => {
    //Valido si la cantidad es mayor que 1
    if (Number.parseInt(record.cantidad) > 1 && tipo === 1) {
      //Resto una unidad
      let newDet = updateTicketDet(record.prd_id, detalleCurTicket, 2, 0);
      updateDetalle(newDet);
      ticketUpdate(2, newDet);
      message.success("Producto Actualizado", 1);
    } else {
      //Retiro el producto del carrito
      let newDet = deleteTicketDet(record.prd_id, detalleCurTicket);
      updateDetalle(newDet);
      ticketUpdate(2, newDet);
      message.success("Producto Eliminado", 1);
    }
  };

  //Funcion para cambiar la lista de precios
  //del ticket actual
  const updateLista = async (value) => {
    updateListaPrc(value);
    let newDet = updateTicketDetLista(value, detalleCurTicket);
    updateDetalle(newDet);
    ticketUpdate(2, newDet);
    updateCurTicket(2, value);
    message.success("Lista Actualizada", 1);
  };

  //Funcion para descartar Ticket
  const deleteTicket = async () => {
    //Crear encabezado en DSC
    const dltTicket = {
      id: curTicket.id,
      fecha: curTicket.fecha,
      turno: curTicket.turno,
      user: curTicket.user,
      nombre: curTicket.nombre,
      cliente: curTicket.cliente,
      clt_id: 2,
      totalTicket: curTicket.totalTicket,
      estado: curTicket.estado,
      lista: curTicket.lista,
      fecha_pago: curTicket.fecha_pago,
      nota: curTicket.nota,
      cobro: curTicket.cobro,
      cambio: curTicket.cambio,
      descuento: curTicket.descuento,
      ip_ticket: curTicket.ip_ticket,
    };
    createTicketDisc(dltTicket);

    //Crear detalle en TMP
    let newDetTmp = addTicketTempDet(discartedDetalle, detalleCurTicket);
    createTicketDiscDetalle(newDetTmp);

    //Genero un nuevo encabezado de Ticket
    let tckNew = genTicket(currentShift.id);
    updateTicket(tckNew);
    ticketUpdate(1, tckNew);

    //Limpiar detalle actTickets
    clearDetalleTicket([]);
    ticketUpdate(2, []);

    //Actualizo la lista de precios
    updateListaPrc(1);

    showNotification("topLeft", 4, "TICKET DESCARTADO CON EXITO", "");
  };

  //Funcion para cerrar la venta
  const saveTicket = async (record, newDetalle) => {
    //Creo el encabezado en Tickets Def
    createTicketDef(record);

    //Creo el detalle en Tickets Def Detalle
    let newDetDef = addTicketTempDet(ticketsDefDetalle, newDetalle);
    createTicketDefDetalle(newDetDef);

    //Genero un nuevo encabezado de Ticket
    let tckNew = genTicket(currentShift.id);
    updateTicket(tckNew);
    ticketUpdate(1, tckNew);

    //Limpiar detalle actTickets
    clearDetalleTicket([]);
    ticketUpdate(2, []);

    //Actualizo la lista de precios
    updateListaPrc(1);

    return true;
  };

  //Funcion para descartar Ticket
  const cancelTicket = async (record) => {
    setIsLoading(true);
    const cur_mov = record;
    let val_gestion = await POS.deleteGestion(
      currentUser,
      2,
      record.id,
      currentShift.id
    );

    if (val_gestion.type && Number.parseInt(val_gestion.type) > 0) {
      deleteGestion(cur_mov, 2);
      showNotification("topRight", 4, "CANCELAR TICKET", "CANCELACION EXITOSA");
    } else {
      showNotification("topRight", 2, "DESCARTAR TICKET", val_gestion.message);
    }

    downData();

    setIsLoading(false);
  };

  /////////// FIN FUNCIONES PARA GESTIONAR VENTA ///////

  //Funcion para descartar Ticket
  // 1 - Ingreso o Gasto, 2 - Ticket
  const deleteGestion = async (record, tipo) => {
    //Valido si es curTicket o Temporal
    if (tipo === 1) {
      //Elimino la gestion de efectivo
      let newEfe = removeHeaderTmp(gestionCash, record.id);
      updateGestion(newEfe);
    } else {
      //Elimino encabezado de ticketsTemp
      let newDef = removeHeaderTmp(ticketsDef, record.id);
      updateTicketDef(newDef);

      //Elimino detalle de ticketsTemDetalle
      let newDefDet = deleteTicketTmpDet(record.id, ticketsDefDetalle);
      updateTicketDefDetalle(newDefDet);
    }
    message.success("PROCESO LOCAL EXITOSO", 1);
  };

  //Funcion para retomar Ticket
  const showTicketDet = async (record) => {
    const detalleFltr = ticketsDefDetalle.filter((deta) => {
      return deta.id === record.id;
    });

    updateDetaTicket(detalleFltr);
    setDrwDetalle(4);
  };

  // MODALES/////////////////////
  const openModal = (title, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setIsVisibleModal(true);
    setTitleModal(title);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

  //Busqueda de productos
  useEffect(() => {
    if (searchProducto) {
      (async () => {
        const filteredProducto = dataPro.filter((producto) => {
          let productoLowercase = producto.criterio.toLowerCase();

          let searchLowercase = searchProducto.toLowerCase();
          return productoLowercase.indexOf(searchLowercase) > -1;
        });
        setListProducts(filteredProducto);
        filteredProducto.length === 0
          ? message.warning("NO SE ENCONTAROS RESULTADOS", 1)
          : message.success(
              filteredProducto.length + " Producto(s) encontrado(s)",
              1
            );
      })();
    } else {
      setListProducts([]);
      message.warning("NO SE ENCONTAROS RESULTADOS", 1);
    }
  }, [searchProducto]);

  //Se ingresan valores de busqueda de items
  const onchangeSearch = async (value) => {
    setSearchProducto(value);
  };

  //Abre el Drawer
  const openDrawer = (drwTipo) => {
    setDrwDetalle(drwTipo);
    //setIsDrawer(!isDrawer);
  };
  //Cierra el Drawer
  const closeDrawer = () => {
    setIsDrawer(false);
  };

  //Se muestran los detalles del Item
  const showDetails = async (record) => {
    updateCurProduct(record);
    openModal(null, 10, "700px");
  };

  //Se actualiza el Barcode del Item
  const updateBcode = async (record) => {
    updateCurProduct(record);
    openModal(null, 5, "350px");
  };

  ///////// FUNCIONES ESPECIALES ////////

  //Generar un nuevo encabezado de ticket
  const genTicket = (turnoId) => {
    let ticketNew = {
      id: uuidv4(),
      fecha: moment(),
      turno: turnoId,
      user: currentUser.id,
      nombre: currentUser.nombre,
      cliente: "CLIENTE MOSTRADOR",
      clt_id: 2,
      totalTicket: 0,
      estado: 0,
      lista: 1,
      fecha_pago: "",
      nota: "",
      cobro: 0,
      cambio: 0,
      efectivo: 0,
      transfer: 0,
      cheques: 0,
      descuento: 0,
      ip_ticket: currentUser.ip_user,
    };

    return ticketNew;
  };

  //Seleccionar encabezado o detalle del Ticket
  const ticketSelect = (tipo) => {
    switch (Number.parseInt(selectedTck)) {
      case 1:
        return Number.parseInt(tipo) === 1 ? ticketOne : ticketOneDet;
      case 2:
        return Number.parseInt(tipo) === 1 ? ticketTwo : ticketTwoDet;
      case 3:
        return Number.parseInt(tipo) === 1 ? ticketThree : ticketThreeDet;
      case 4:
        return Number.parseInt(tipo) === 1 ? ticketFour : ticketFourDet;
      case 5:
        return Number.parseInt(tipo) === 1 ? ticketFive : ticketFiveDet;
      default:
        return false;
    }
  };

  //Actualizo encabezado o detalle del Ticket
  const ticketUpdate = (tipo, tckObject) => {
    switch (Number.parseInt(selectedTck)) {
      case 1:
        Number.parseInt(tipo) === 1
          ? updateOne(tckObject)
          : updateOneDet(tckObject);
        break;
      case 2:
        Number.parseInt(tipo) === 1
          ? updateTwo(tckObject)
          : updateTwoDet(tckObject);
        break;
      case 3:
        Number.parseInt(tipo) === 1
          ? updateThree(tckObject)
          : updateThreeDet(tckObject);
        break;
      case 4:
        Number.parseInt(tipo) === 1
          ? updateFour(tckObject)
          : updateFourDet(tckObject);
        break;
      case 5:
        Number.parseInt(tipo) === 1
          ? updateFive(tckObject)
          : updateFiveDet(tckObject);
        break;
      default:
        return false;
    }
  };

  //Inserto registro en el detalle del Ticket
  const ticketCreate = (newItem) => {
    switch (Number.parseInt(selectedTck)) {
      case 1:
        createOneDet(newItem);
        break;
      case 2:
        createTwoDet(newItem);
        break;
      case 3:
        createThreeDet(newItem);
        break;
      case 4:
        createFourDet(newItem);
        break;
      case 5:
        createFiveDet(newItem);
        break;
      default:
        return false;
    }
  };

  //Funcion para cambiar de Ticket
  const shangeTicket = async (numTck) => {
    if (Number.parseInt(selectedTck) !== Number.parseInt(numTck)) {
      //cargar en curTicket el seleccionado
      switch (Number.parseInt(numTck)) {
        case 1:
          updateTicket(ticketOne);
          updateDetalle(ticketOneDet);
          break;
        case 2:
          updateTicket(ticketTwo);
          updateDetalle(ticketTwoDet);
          break;
        case 3:
          updateTicket(ticketThree);
          updateDetalle(ticketThreeDet);
          break;
        case 4:
          updateTicket(ticketFour);
          updateDetalle(ticketFourDet);
          break;
        case 5:
          updateTicket(ticketFive);
          updateDetalle(ticketFiveDet);
          break;
        default:
          return false;
      }
      changeTicket(numTck);
    }
    setDrwDetalle(1);
  };

  const downData = async () => {
    setIsLoading(true);
    let reLoad = await downloadDataPos(currentUser, currentShift.id);
    if (reLoad) {
      updateInventory(reLoad.inventario);
      updateEnviroment(reLoad.entorno);
      setListProducts([]);
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS DESCARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  const upBackUps = async () => {
    setIsLoading(true);
    const savedBackups = {
      fecha: new Date(),
      backUp: curBackUps,
    };
    console.log(savedBackups);
    //Subo los datos
    let reLoad = await uploadBackUpPos(
      currentUser,
      savedBackups,
      currentShift.id
    );
    if (reLoad) {
      showNotification(
        "topRight",
        4,
        "SUBIR BACKUP",
        "BACKUPS CARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SUBIR BACKUP",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => downData(),
      src: download,
      style: { color: "red" },
    },
    {
      onClick: () => openDrawer(3),
      src: calendar,
    },
    {
      onClick: () => openDrawer(7),
      src: itemsSold,
    },
    {
      onClick: () => openDrawer(6),
      src: prepayed,
    },
    {
      onClick: () => openDrawer(5),
      src: briefcase,
    },
  ];
  //Fin floating

  return !isLogued ? (
    <Redirect to="/" />
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <TitlePage title="Invoice" />
      <div style={{ height: "50" }}>
        <MainHeader
          setIsLoading={setIsLoading}
          currentUser={currentUser}
          userOptions={userOptions}
          currentShift={currentShift}
          openModal={openModal}
          openDrawer={openDrawer}
          curTicket={curTicket}
          myNumber={myNumber}
          curCurrency={curCurrency}
          discartedDetalle={discartedDetalle}
          ticketsDefDetalle={ticketsDefDetalle}
          ticketsDiscarted={ticketsDiscarted}
          ticketsDef={ticketsDef}
          shiftsList={shiftsList}
          ticketsTemp={ticketsTemp}
          gestionCash={gestionCash}
          updateEnviroment={updateEnviroment}
          setListProducts={setListProducts}
          downData={downData}
          ticketsTurno={ticketsTurno}
          ticketsTurnoDet={ticketsTurnoDet}
          resumenTurno={resumenTurno}
        />
      </div>
      <Row
        gutter={{ xs: 2, sm: 2, md: 2, lg: 2 }}
        style={{ background: "#BABEBE" }}
      >
        <Col xl={16} lg={12} md={12} sm={12} xs={4}>
          <div
            className="site-layout-background"
            style={{
              padding: 2,
              textAlign: "center",
              height: window.innerHeight - 95,
              overflow: "auto",
              backgroundColor: "#BABEBE",
            }}
          >
            <TicketSearch
              onchangeSearch={onchangeSearch}
              openDrawer={openDrawer}
            />
            <ProductsCatalogo
              myNumber={myNumber}
              validateRows={validateRows}
              listProducts={listProducts}
              showDetails={showDetails}
              curCurrency={curCurrency}
              updateBcode={updateBcode}
            />
          </div>
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={20}>
          <div
            className="site-layout-background"
            style={{
              padding: 10,
              height: 60,
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#f7f7f7",
              borderBottomWidth: "0.4px",
              borderBottomColor: "#E6E6EA",
              borderBottomStyle: "solid",
            }}
          >
            <Tooltip placement="top" title="Ticket Nº 1" color={colorPrm}>
              <Button
                className={
                  selectedTck === 1 ? curStyle.btnInfo : curStyle.btnOrange
                }
                shape="circle"
                type="primary"
                size="large"
                onClick={() => shangeTicket(1)}
                icon={<ShoppingOutlined />}
                style={{ marginLeft: 20 }}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Tickets Nº 2" color={colorPrm}>
              <Button
                className={
                  selectedTck === 2 ? curStyle.btnInfo : curStyle.btnOrange
                }
                shape="circle"
                type="primary"
                size="large"
                onClick={() => shangeTicket(2)}
                icon={<ShoppingOutlined />}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Tickets Nº 3" color={colorPrm}>
              <Button
                className={
                  selectedTck === 3 ? curStyle.btnInfo : curStyle.btnOrange
                }
                shape="circle"
                type="primary"
                size="large"
                onClick={() => shangeTicket(3)}
                icon={<ShoppingOutlined />}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Ticket Nº 4" color={colorPrm}>
              <Button
                className={
                  selectedTck === 4 ? curStyle.btnInfo : curStyle.btnOrange
                }
                shape="circle"
                type="primary"
                size="large"
                onClick={() => shangeTicket(4)}
                icon={<ShoppingOutlined />}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Ticket Nº 5" color={colorPrm}>
              <Button
                className={
                  selectedTck === 5 ? curStyle.btnInfo : curStyle.btnOrange
                }
                shape="circle"
                type="primary"
                size="large"
                onClick={() => shangeTicket(5)}
                icon={<ShoppingOutlined />}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
            <Select
              showSearch
              size="large"
              showArrow
              defaultValue={priceList}
              onChange={updateLista}
              style={{ paddingLeft: 8, width: 150 }}
              placeholder="LISTA DE PRECIOS"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listasPrecios.map((precio) => (
                <Option key={precio.id} value={precio.id}>
                  {precio.nombre}
                </Option>
              ))}
            </Select>
          </div>
          <div
            className="site-layout-background"
            style={{
              padding: 6,
              height: window.innerHeight - 205,
              overflow: "auto",
              background: "#f7f7f7",
            }}
          >
            {drwDetalle === 1 ? (
              <Cart
                myNumber={myNumber}
                validateRows={validateRows}
                deleteRows={deleteRows}
                updateRows={updateRows}
                updateCurTicket={updateCurTicket}
                curCurrency={curCurrency}
              />
            ) : drwDetalle === 3 ? (
              <TicketsShift
                myNumber={myNumber}
                showTicketDet={showTicketDet}
                setDrwDetalle={setDrwDetalle}
                curCurrency={curCurrency}
                updateDetaTicket={updateDetaTicket}
                cancelTicket={cancelTicket}
              />
            ) : drwDetalle === 4 ? (
              <DetalleTicket
                myNumber={myNumber}
                validateRows={validateRows}
                deleteRows={deleteRows}
                updateRows={updateRows}
                updateCurTicket={updateCurTicket}
                curCurrency={curCurrency}
                setDrwDetalle={setDrwDetalle}
                showDetalle={showDetalle}
              />
            ) : drwDetalle === 5 ? (
              <Debtors myNumber={myNumber} curCurrency={curCurrency} />
            ) : drwDetalle === 6 ? (
              <Prepaied myNumber={myNumber} curCurrency={curCurrency} />
            ) : (
              <SoldItems myNumber={myNumber} curCurrency={curCurrency} />
            )}
          </div>
          <div
            className="site-layout-background"
            style={{
              padding: 10,
              textAlign: "center",
              height: 60,
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#f7f7f7",
            }}
          >
            {drwDetalle === 1 ? (
              <>
                <Title
                  level={4}
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: colorPrm,
                    fontSize: 18,
                  }}
                >
                  {Number.parseFloat(curTicket.totalTicket) === 0
                    ? "TICKET Nº " +
                      selectedTck +
                      " " +
                      curCurrency.simbolo +
                      "0.00 "
                    : "TICKET Nº " +
                      selectedTck +
                      " " +
                      curCurrency.simbolo +
                      myNumber(1, curTicket.totalTicket)}
                </Title>
                <Tooltip
                  title={"Cerrar Venta"}
                  placement="top"
                  color={colorSec}
                >
                  <Button
                    type="primary"
                    shape="round"
                    className={curStyle.btnInfo}
                    style={{
                      marginLeft: 20,
                      marginRight: 20,
                      display: curTicket.totalTicket > 0 ? "block" : "none",
                    }}
                    onClick={() =>
                      curTicket.totalTicket > 0
                        ? openModal("Cerrar Ticket", 4, "750px")
                        : showNotification(
                            "topLeft",
                            2,
                            "CERRAR TICKET",
                            "NO HAY VENTA PARA CERRAR"
                          )
                    }
                    icon={<DollarCircleOutlined />}
                  >
                    {" "}
                    Facturar
                  </Button>
                </Tooltip>
                <Popconfirm
                  placement="left"
                  title="Descartar la Venta?"
                  onConfirm={() => deleteTicket()}
                  okText="Si"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    shape="round"
                    className={curStyle.btnInverse}
                    style={{
                      display: curTicket.totalTicket > 0 ? "block" : "none",
                    }}
                    icon={<DeleteOutlined />}
                  >
                    {" "}
                    Descartar
                  </Button>
                </Popconfirm>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <OpenShift
            genTicket={genTicket}
            currentUser={currentUser}
            closeModal={closeModal}
            createOne={createOne}
            createTwo={createTwo}
            createThree={createThree}
            createFour={createFour}
            createFive={createFive}
            updateOneDet={updateOneDet}
            updateTwoDet={updateTwoDet}
            updateThreeDet={updateThreeDet}
            updateFourDet={updateFourDet}
            updateFiveDet={updateFiveDet}
          />
        ) : tipoModal === 2 ? (
          <CloseShift
            myNumber={myNumber}
            currentUser={currentUser}
            currentShift={currentShift}
            closeModal={closeModal}
            varEffec={Math.random()}
            curCurrency={curCurrency}
            ticketsDef={ticketsDef}
            gestionCash={gestionCash}
            deleteGestion={deleteGestion}
            updateInventory={updateInventory}
            updateCurProduct={updateCurProduct}
          />
        ) : tipoModal === 4 ? (
          <CloseTicket
            myNumber={myNumber}
            currentUser={currentUser}
            curTicket={curTicket}
            detalleCurTicket={detalleCurTicket}
            closeModal={closeModal}
            customersList={customersList}
            saveTicket={saveTicket}
            varEffec={Math.random()}
            curCurrency={curCurrency}
            updateInventory={updateInventory}
            setListProducts={setListProducts}
            filtrarArray={filtrarArray}
            updateEstadoTicketDet={updateEstadoTicketDet}
            updateEnviroment={updateEnviroment}
            setLoadInventory={setLoadInventory}
            updateCurProduct={updateCurProduct}
          />
        ) : tipoModal === 5 ? (
          <UpdateBarCode
            currentUser={currentUser}
            curProduct={curProduct}
            closeModal={closeModal}
            varEffec={Math.random()}
            setListProducts={setListProducts}
            updateInventory={updateInventory}
            setLoadInventory={setLoadInventory}
            updateEnviroment={updateEnviroment}
            currentShift={currentShift}
          />
        ) : tipoModal === 7 ? (
          <GestionEfectivo
            currentUser={currentUser}
            titleModal={titleModal}
            closeModal={closeModal}
            varEffec={Math.random()}
            currentShift={currentShift}
          />
        ) : (
          <ProductDetails closeModal={closeModal} varEffec={Math.random()} />
        )}
      </ModalApp>
      <FloatingButtons
        buttonType="plus"
        buttonColor={"#ffffff"}
        backgroundColor={"#3498db"}
        dimension={60}
        buttonsList={buttonsList}
        top={"calc(85% - 25px)"}
        left={"120px"}
        direction="circular"
        degree={-180}
        distance={90}
      />
      <Footer
        style={{
          textAlign: "center",
          bottom: 0,
          maxHeight: 50,
          paddingBottom: 15,
          paddingTop: 15,
          backgroundColor: "#ffffff",
        }}
      >
        <MainFooter setIsLoading={setIsLoading} curFecha={new Date()} />
      </Footer>
    </Layout>
  );
}
