export const createShiftsAction = (state) => {
  return {
    type: "CREATE_SHIFTS",
    payload: state,
  };
};

export const updateShiftsAction = (state) => {
  return {
    type: "UPDATE_SHIFTS_SENT",
    payload: state,
  };
};
