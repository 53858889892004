import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDL6Vk2nC2rNSEIoTLa1-Oq6E0D0Ua762w",
  authDomain: "aal-erp.firebaseapp.com",
  databaseURL: "https://aal-erp.firebaseio.com",
  projectId: "aal-erp",
  storageBucket: "aal-erp.appspot.com",
  messagingSenderId: "836837993372",
  appId: "1:836837993372:web:8a1d0590e46c3e128b7659",
};

export default firebase.initializeApp(firebaseConfig);
