export const createTicketDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_DETALLE",
    payload: state,
  };
};

export const updateTicketDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_DETALLE",
    payload: state,
  };
};

export const removeTicketDetalleAction = (state) => {
  return {
    type: "REMOVE_TICKET_DETALLE",
    payload: state,
  };
};
