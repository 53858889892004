const initialProps = {
  ticketsTemp: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_TEMP":
      return {
        ...state,
        ticketsTemp: [...state.ticketsTemp, action.payload],
      };
    case "UPDATE_TICKET_TEMP":
      return {
        ...state,
        ticketsTemp: action.payload,
      };
    default:
      return state;
  }
}
