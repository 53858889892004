export const createTicketTwoDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_TWO_DETALLE",
    payload: state,
  };
};

export const updateTicketTwoDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_TWO_DETALLE",
    payload: state,
  };
};

export const removeTicketTwoDetalleAction = (state) => {
  return {
    type: "REMOVE_TICKET_TWO_DETALLE",
    payload: state,
  };
};
