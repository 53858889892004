const initialProps = {
  priceList: 1,
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "UPDATE_PRICE_LIST":
      return {
        ...state,
        priceList: action.payload,
      };
    default:
      return state;
  }
}
