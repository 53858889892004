export const createTicketTempAction = (state) => {
  return {
    type: "CREATE_TICKET_TEMP",
    payload: state,
  };
};

export const updateTicketTempAction = (state) => {
  return {
    type: "UPDATE_TICKET_TEMP",
    payload: state,
  };
};
