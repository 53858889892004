export const createTicketsAction = (state) => {
  return {
    type: "CREATE_TICKETS",
    payload: state,
  };
};

export const updateTicketsAction = (state) => {
  return {
    type: "UPDATE_TICKETS",
    payload: state,
  };
};
