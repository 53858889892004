import React, { useState, useEffect } from "react";
import {
  InputNumber,
  Button,
  Divider,
  Typography,
  Popconfirm,
  message,
  List,
  Tabs,
  Spin,
  Space,
} from "antd";

import Icon from "@mdi/react";
import { mdiTrashCanOutline } from "@mdi/js";

import moment from "moment";

//Iconos y constantes
import {
  ShoppingOutlined,
  PrinterOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateShiftAction } from "../../../actions/Pos/shiftActions";
import { createShiftsAction } from "../../../actions/Pos/shiftsListActions";
import { createTicketAction } from "../../../actions/Pos/curTicketActions";
import { updateTicketDetalleAction } from "../../../actions/Pos/curTicketDetalleActions";
//Funciones para administrar el carrito
import { getResumenTurno } from "../../../utils/ArrayFunctions";

//Componentes Personalizados
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import POS from "../../../utils/PosService";

//Estilos
import "./CloseShift.scss";
import curStyle from "../../../css/GlobalCmp.module.css";

export default function CloseTurn(props) {
  const {
    myNumber,
    currentUser,
    currentShift,
    closeModal,
    varEffec,
    curCurrency,
    ticketsDef,
    gestionCash,
    deleteGestion,
    updateInventory,
    updateCurProduct,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [cierreCaja, setCierreCaja] = useState(0);
  const [cierreNota, setCierreNota] = useState("");
  const [valorSistema, setValorSistema] = useState(currentShift.valorBase);
  const [gestionVta, setGestionVta] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [btnPrint, setBtnPrint] = useState(0);

  const { Title } = Typography;
  const { TabPane } = Tabs;

  //Ejecutar dispatch
  const dispatch = useDispatch();
  const updateShift = (state) => dispatch(updateShiftAction(state));
  const createShifts = (state) => dispatch(createShiftsAction(state));
  const createTicket = (state) => dispatch(createTicketAction(state));
  const clearTicketDetalle = (state) =>
    dispatch(updateTicketDetalleAction(state));

  //Produto actual para detalle o edicion de ticket
  const prnSaved = useSelector((state) => state.currentProduct.currentProduct);
  const rusuVenta = useSelector(
    (state) => state.enviroment.enviroment.resumen_tickets
  );
  const listaTickets = useSelector(
    (state) => state.enviroment.enviroment.tickets_turno
  );

  useEffect(() => {
    (async () => {
      let gVenta = getResumenTurno(
        ticketsDef,
        gestionCash,
        currentShift.valorBase,
        rusuVenta
      );
      setGestionVta(gVenta);
      setValorSistema(currentShift.valorBase);
    })();
  }, []);

  const arrayBase = [
    {
      id: 1,
      titulo: "EFECTIVO BASE",
      valor: curCurrency.simbolo + myNumber(1, valorSistema),
    },
    {
      id: 2,
      titulo: "TOTAL VENTAS (" + gestionVta.num_tickets + ")",
      valor: curCurrency.simbolo + myNumber(1, gestionVta.ventas),
    },
    {
      id: 3,
      titulo: "TOTAL COBROS",
      valor: curCurrency.simbolo + myNumber(1, gestionVta.cobros),
    },
  ];

  const arraySaldo = [
    {
      id: 1,
      titulo: "SALDO EN EFECTIVO",
      valor: 0,
    },
  ];

  const arrayDiferencia = [
    {
      id: 1,
      titulo: "FALTANTE",
      valor: 0,
    },
  ];

  const onChangeCaja = (value) => {
    if (Number.parseInt(value) >= 0) {
      setCierreCaja(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const deleteMoves = async (record, tipo) => {
    setIsLoading(true);
    const cur_mov = record;
    let val_gestion = await POS.deleteGestion(
      currentUser,
      tipo,
      record.id,
      currentShift.id
    );

    if (Number.parseInt(val_gestion.type) > 0) {
      deleteGestion(cur_mov, tipo);
      showNotification(
        "topRight",
        4,
        "GESTION DE EFECTIVO",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE EFECTIVO",
        val_gestion.message
      );
    }
    setIsLoading(false);
  };

  const cerrarTurno = async () => {
    setLoadingButton(true);
    const oldTurno = {
      id: currentShift.id,
      fecha: currentShift.fecha,
      user: currentShift.user,
      nombre: currentShift.nombre,
      valorBase: Number.parseFloat(currentShift.valorBase),
      valorVentas: Number.parseFloat(gestionVta.ventas),
      valorCobros: Number.parseFloat(gestionVta.cobros),
      valorIngresos: Number.parseFloat(gestionVta.ingresos),
      valorEgresos: Number.parseFloat(gestionVta.egresos),
      valorSaldo: Number.parseFloat(gestionVta.saldo),
      valorCierre: Number.parseFloat(cierreCaja),
      notaCierre: cierreNota,
      cierre: moment(),
    };
    createShifts(oldTurno);
    const turnoPrn = {
      formato: 2,
      turno_header: oldTurno,
      turno_items: gestionCash,
    };
    updateCurProduct(turnoPrn);
    const turno = {
      user: 0,
    };
    updateShift(turno);
    const curTicket = {
      id: "0",
      fecha: moment(),
      user: 0,
      nombre: "",
      cliente: "",
      clt_id: 0,
      totalTicket: 0,
      estado: 0,
      lista: 1,
      fecha_pago: "",
      nota: "",
      cobro: 0,
      cambio: 0,
      efectivo: 0,
      transfer: 0,
      cheques: 0,
    };
    createTicket(curTicket);
    const curTicketDetalle = [];
    clearTicketDetalle(curTicketDetalle);
    message.success("TURNO CERRADO CON EXITO", 4);
    showNotification(
      "topRight",
      4,
      "CERRAR TURNO",
      "SICRONICE LOS DATOS PARA COMPLETAR EL PROCESO"
    );
    setLoadingButton(false);
    setBtnPrint(1);
  };

  //Imprimir el Cierre
  const prnCierre = async () => {
    window.open(
      "https://api.aal-erp.com/pos/api/714?objeto=" + JSON.stringify(prnSaved),
      "_blank"
    );
  };
  //Fin imprimir Cierre

  return (
    <>
      <Title
        level={4}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 500,
          textAlign: "center",
          color: CST.colorGrn,
        }}
      >
        {"CERRAR TURNO " + currentUser.nombre}
      </Title>
      <Divider orientation="center" style={{ marginTop: 0, marginBottom: 0 }}>
        {"APERTURA: " +
          moment(currentShift.fecha).format("DD-MM-YYYY") +
          " A LAS " +
          moment(currentShift.fecha).format("HH:mm")}
      </Divider>
      <Tabs defaultActiveKey="1">
        <TabPane tab="CERRAR TURNO" key="1">
          <List
            size="small"
            dataSource={arrayBase}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        color: item.id !== 2 ? CST.colorGrn : CST.colorGry,
                        fontSize: 17,
                      }}
                    >
                      {item.titulo}
                    </Title>
                  }
                />
                <div>
                  <Title
                    level={4}
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: 500,
                      textAlign: "right",
                      color: item.id !== 2 ? CST.colorGrn : CST.colorGry,
                      fontSize: 17,
                    }}
                  >
                    {item.valor}
                  </Title>
                </div>
              </List.Item>
            )}
          />
          <Divider
            orientation="center"
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            Ingresos y Salidas de Efectivo
          </Divider>

          <List
            size="small"
            dataSource={gestionCash}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        color: item.tipo === 1 ? CST.colorGrn : CST.colorSec,
                        fontSize: 17,
                      }}
                    >
                      {item.tipo === 1
                        ? item.nota + " ( + )"
                        : item.nota + " ( - )"}
                    </Title>
                  }
                />
                <div>
                  <Title
                    level={4}
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: 500,
                      textAlign: "right",
                      color: item.tipo === 1 ? CST.colorGrn : CST.colorSec,
                      fontSize: 17,
                    }}
                  >
                    {curCurrency.simbolo + myNumber(1, item.valor)}
                  </Title>
                </div>
              </List.Item>
            )}
          />
          <Divider
            orientation="center"
            style={{ marginTop: 0, marginBottom: 15 }}
          >
            SALDO EN EFECTIVO Y DIFERENCIA
          </Divider>
          <List
            size="small"
            dataSource={arrayDiferencia}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        color: CST.colorGry,
                        fontSize: 18,
                      }}
                    >
                      {"TOTAL (BASE + COBROS + INGRESOS) - SALIDAS"}
                    </Title>
                  }
                />
                <div>
                  <Title
                    level={4}
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: 500,
                      textAlign: "right",
                      color: CST.colorGry,
                      fontSize: 18,
                    }}
                  >
                    {curCurrency.simbolo + myNumber(1, gestionVta.saldo)}
                  </Title>
                </div>
              </List.Item>
            )}
          />
          <List
            size="small"
            dataSource={arraySaldo}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        color: CST.colorGry,
                        fontSize: 18,
                      }}
                    >
                      {item.titulo}
                    </Title>
                  }
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={0}
                    value={cierreCaja}
                    onChange={onChangeCaja}
                    style={{ width: "70%", fontSize: 18 }}
                  />
                </div>
              </List.Item>
            )}
          />
          <List
            size="small"
            dataSource={arrayDiferencia}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        color:
                          cierreCaja - gestionVta.saldo > 0
                            ? CST.colorGrn
                            : CST.colorSec,
                        fontSize: 18,
                      }}
                    >
                      {cierreCaja - gestionVta.saldo > 0
                        ? "SOBRANTE"
                        : "FALTANTE"}
                    </Title>
                  }
                />
                <div>
                  <Title
                    level={4}
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: 500,
                      textAlign: "right",
                      color:
                        cierreCaja - gestionVta.saldo > 0
                          ? CST.colorGrn
                          : CST.colorSec,
                      fontSize: 18,
                    }}
                  >
                    {curCurrency.simbolo +
                      myNumber(1, cierreCaja - gestionVta.saldo)}
                  </Title>
                </div>
              </List.Item>
            )}
          />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <div>
            {btnPrint === 0 ? (
              <Popconfirm
                placement="top"
                title="¿ DESEA CERRAR EL TURNO ?"
                onConfirm={cerrarTurno}
                okText="Confirmar"
                cancelText="Cancelar"
                icon={<ShoppingOutlined style={{ color: CST.colorGry }} />}
              >
                <Button
                  type="primary"
                  className={curStyle.btnInfo}
                  shape="round"
                  size="large"
                  block
                  loading={loadingButton}
                >
                  <ShoppingOutlined /> CERRAR EL TURNO
                </Button>
              </Popconfirm>
            ) : (
              <Space direction="horizontal" align="center">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  className={curStyle.btnBlueLight}
                  onClick={() => prnCierre()}
                >
                  <PrinterOutlined /> IMPRIMIR CIERRE
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  className={curStyle.btnInfo}
                  onClick={() => closeModal()}
                >
                  <LogoutOutlined /> SALIR DEL CIERRE
                </Button>
              </Space>
            )}
          </div>
        </TabPane>
        <TabPane tab="VER VENTAS" key="2">
          <List
            size="small"
            dataSource={arraySaldo}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 700,
                        textAlign: "left",
                        color: CST.colorGrn,
                        fontSize: 17,
                      }}
                    >
                      NOMBRE CLIENTE
                    </Title>
                  }
                />
                <div
                  style={{ display: "flex", flexDirection: "row", width: 300 }}
                >
                  <div style={{ width: 150, textAlign: "center" }}>
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 700,
                        textAlign: "center",
                        color: CST.colorGrn,
                        fontSize: 17,
                      }}
                    >
                      VENTA
                    </Title>
                  </div>
                  <div style={{ width: 150, textAlign: "center" }}>
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 700,
                        textAlign: "center",
                        color: CST.colorGrn,
                        fontSize: 17,
                      }}
                    >
                      COBRADO
                    </Title>
                  </div>
                </div>
              </List.Item>
            )}
          />
          <List
            size="small"
            dataSource={listaTickets}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "left",
                        color: CST.colorGry,
                        fontSize: 17,
                      }}
                    >
                      {item.cliente}
                    </Title>
                  }
                />
                <div
                  style={{ display: "flex", flexDirection: "row", width: 300 }}
                >
                  <div style={{ width: 150, textAlign: "right" }}>
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "right",
                        color: CST.colorGry,
                        fontSize: 17,
                      }}
                    >
                      {curCurrency.simbolo + myNumber(1, item.total_ticket)}
                    </Title>
                  </div>
                  <div style={{ width: 150, textAlign: "right" }}>
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 500,
                        textAlign: "right",
                        color:
                          item.totalTicket !== item.cobro
                            ? CST.colorSec
                            : CST.colorGry,
                        fontSize: 17,
                      }}
                    >
                      {curCurrency.simbolo + myNumber(1, item.cobro)}
                    </Title>
                  </div>
                </div>
              </List.Item>
            )}
          />
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />

          <List
            size="small"
            dataSource={arraySaldo}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 700,
                        textAlign: "left",
                        color: CST.colorGrn,
                        fontSize: 17,
                      }}
                    >
                      TOTALES POR VENTAS
                    </Title>
                  }
                />
                <div
                  style={{ display: "flex", flexDirection: "row", width: 300 }}
                >
                  <div style={{ width: 150, textAlign: "right" }}>
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 700,
                        textAlign: "right",
                        color: CST.colorGrn,
                        fontSize: 17,
                      }}
                    >
                      {curCurrency.simbolo + myNumber(1, gestionVta.ventas)}
                    </Title>
                  </div>
                  <div style={{ width: 150, textAlign: "right" }}>
                    <Title
                      level={4}
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 700,
                        textAlign: "right",
                        color: CST.colorGrn,
                        fontSize: 17,
                      }}
                    >
                      {curCurrency.simbolo + myNumber(1, gestionVta.cobros)}
                    </Title>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="VER EFECTIVO" key="3">
          {isLoading ? (
            <div style={{ textAlign: "center", margin: 50 }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <List
                size="small"
                dataSource={arraySaldo}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "left",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          DETALLE MOVIMIENTO
                        </Title>
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 300,
                      }}
                    >
                      <div style={{ width: 130, textAlign: "center" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "center",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          INGRESO
                        </Title>
                      </div>
                      <div style={{ width: 130, textAlign: "center" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "center",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          SALIDA
                        </Title>
                      </div>
                      <div style={{ width: 40, textAlign: "center" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "center",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          Elim
                        </Title>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
              <List
                size="small"
                dataSource={gestionCash}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 500,
                            textAlign: "left",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          {item.nota}
                        </Title>
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 300,
                      }}
                    >
                      <div style={{ width: 125, textAlign: "right" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 500,
                            textAlign: "right",
                            color: CST.colorGrn,
                            fontSize: 17,
                          }}
                        >
                          {item.tipo === 1
                            ? curCurrency.simbolo + myNumber(1, item.valor)
                            : curCurrency.simbolo + "0.00"}
                        </Title>
                      </div>
                      <div style={{ width: 125, textAlign: "right" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 500,
                            textAlign: "right",
                            color: CST.colorSec,
                            fontSize: 17,
                          }}
                        >
                          {item.tipo === 2
                            ? curCurrency.simbolo + myNumber(1, item.valor)
                            : curCurrency.simbolo + "0.00"}
                        </Title>
                      </div>
                      <div style={{ width: 50, textAlign: "right" }}>
                        <Popconfirm
                          placement="left"
                          title="Eliminar el MOVIMIENTO?"
                          onConfirm={() => deleteMoves(item, 1)}
                          okText="Si"
                          cancelText="No"
                        >
                          <Icon
                            path={mdiTrashCanOutline}
                            size={"26px"}
                            color={CST.colorGry}
                          />
                        </Popconfirm>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
              <List
                size="small"
                dataSource={arraySaldo}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "left",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          TOTA MOVIMIENTOS
                        </Title>
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 300,
                      }}
                    >
                      <div style={{ width: 125, textAlign: "right" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "right",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          {curCurrency.simbolo +
                            myNumber(1, gestionVta.ingresos)}
                        </Title>
                      </div>
                      <div style={{ width: 125, textAlign: "right" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            textAlign: "right",
                            color: CST.colorGry,
                            fontSize: 17,
                          }}
                        >
                          {curCurrency.simbolo +
                            myNumber(1, gestionVta.egresos)}
                        </Title>
                      </div>
                      <div style={{ width: 50, textAlign: "right" }}>
                        <Title
                          level={4}
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 500,
                            textAlign: "right",
                            color: "white",
                            fontSize: 17,
                          }}
                        >
                          <Icon
                            path={mdiTrashCanOutline}
                            size={"26px"}
                            color="white"
                          />
                        </Title>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </>
          )}
        </TabPane>
      </Tabs>
    </>
  );
}
