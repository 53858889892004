import React, { useState, useEffect } from "react";
import {
  List,
  InputNumber,
  Button,
  Divider,
  Spin,
  Tooltip,
  Empty,
  Space,
  message,
  Popconfirm,
  Typography,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { MinusOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

// Importacion de constantes
import { colorSec, colorPrm } from "../../../constants";
import CST from "../../../utils/CustomSettings";

// Redux
import { useSelector } from "react-redux";

export default function Cart(props) {
  const {
    myNumber,
    validateRows,
    deleteRows,
    updateRows,
    updateCurTicket,
    curCurrency,
  } = props;
  const [curItem, setCurItem] = useState({});
  const [prcView, setPrcView] = useState(true);

  //useSelector para acceder al store
  //Ticket actualmente selccionado
  const selectedTck = useSelector(
    (state) => state.selectedTicket.selectedTicket
  );

  const detalleCurTicket = useSelector(
    (state) => state.curTicketDetalle.curTicketDetalle
  );

  const [cartItems, setCartItems] = useState([]);
  const [cargando, setCargando] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // para mostrar el scroball cuando supere los 14 productos seleccionads
  const handleInfiniteOnLoad = () => {
    setLoading(true);
    if (detalleCurTicket.length > 2) {
      message.warning("Infinite List loaded all");
      setHasMore(false);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  //Actualizar el carrito
  useEffect(() => {
    setCartItems(detalleCurTicket);
    if (detalleCurTicket.length > 0) {
      updateCurTicket(1, 0);
    }
  }, [selectedTck, detalleCurTicket]);

  // Actualizar la cantidad
  const onChangeCantProd = (value) => {
    if (Number.parseInt(value) > 0) {
      if (Number.parseInt(value) <= Number.parseInt(curItem.disponible)) {
        updateRows(curItem, value, 1);
      } else {
        message.error("CANTIDAD NO DISPONIBLE!!", 2);
        return false;
      }
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  //Validacion para actuaizar el precio
  const onChangePrecioProd = (value) => {
    if (Number.parseFloat(value) > 0) {
      updateRows(curItem, value, 2);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const { Title } = Typography;

  return (
    <div className="cart" style={{ minHeight: window.innerHeight - 170 }}>
      <div
        style={{
          padding: "8 24 0 0",
          minHeight: window.innerHeight - 200,
          overflow: "auto",
        }}
      >
        {Object.keys(detalleCurTicket).length !== 0 ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <List
              style={{ marginTop: -10 }}
              itemLayout="horizontal"
              loading={cargando}
              dataSource={cartItems}
              renderItem={(item) => (
                <List.Item
                  style={{
                    marginTop: 1,
                    marginBottom: 1,
                    padding: 3,
                    height: 70,
                  }}
                >
                  <List.Item.Meta
                    title={item.descripcion}
                    description={
                      <div>
                        <span
                          style={{
                            color: colorPrm,
                            cursor: "pointer",
                            fontWeight: "600",
                          }}
                        >
                          {prcView ? (
                            <Title
                              level={4}
                              style={{
                                margin: 0,
                                padding: 0,
                                color: CST.orangeBdy,
                                fontSize: 16,
                              }}
                              onClick={() => setPrcView(!prcView)}
                            >
                              {curCurrency.simbolo +
                                myNumber(1, item.precio) +
                                " | " +
                                item.codigo +
                                " | SUBTOTAL: " +
                                curCurrency.simbolo +
                                myNumber(1, item.cantidad * item.precio)}
                            </Title>
                          ) : (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={onChangePrecioProd}
                                onFocus={() => setCurItem(item)}
                                defaultValue={item.precio}
                                value={item.precio}
                                min={item.precioz}
                                size={"small"}
                                style={{ width: 70 }}
                              />
                              <Title
                                level={4}
                                style={{
                                  margin: 0,
                                  marginLeft: 5,
                                  padding: 0,
                                  color: colorSec,
                                  fontSize: 16,
                                }}
                                onClick={() => setPrcView(!prcView)}
                              >
                                {" | " +
                                  item.codigo +
                                  " | SUBTOTAL: " +
                                  curCurrency.simbolo +
                                  myNumber(1, item.cantidad * item.precio)}
                              </Title>
                            </div>
                          )}
                        </span>
                      </div>
                    }
                  />
                  <div style={{ marginRight: 10 }}>
                    <Space>
                      <Button
                        className="secondary-color button-carrito-productos"
                        shape="circle"
                        icon={<MinusOutlined />}
                        onClick={() => deleteRows(item, 1)}
                      />

                      <Tooltip
                        trigger={["focus"]}
                        title={"Ingrese Cantidad"}
                        placement="topLeft"
                        color={colorSec}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          onChange={onChangeCantProd}
                          onFocus={() => setCurItem(item)}
                          defaultValue={item.cantidad}
                          value={item.cantidad}
                          min={1}
                          max={999999}
                          size={"middle"}
                          style={{ width: 70, textAlign: "right" }}
                        />
                      </Tooltip>

                      <Button
                        className="secondary-color button-carrito-productos"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => validateRows(item)}
                      />
                    </Space>
                  </div>
                  <Popconfirm
                    placement="left"
                    title="Eliminar el producto?"
                    onConfirm={() => deleteRows(item, 2)}
                    okText="Si"
                    cancelText="No"
                  >
                    <Button
                      type="primary"
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </List.Item>
              )}
            >
              {loading && hasMore && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 40,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Spin />
                </div>
              )}
            </List>
            <Divider style={{ marginTop: 3, paddingTop: 3, padding: 3 }} />
          </InfiniteScroll>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
              paddingLeft: 8,
              minHeight: window.innerHeight - 270,
            }}
            description={"TICKET Nº " + selectedTck + " SIN ITEMS"}
          />
        )}
      </div>
    </div>
  );
}
