export const createTicketTempDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_TEMP_DETALLE",
    payload: state,
  };
};

export const updateTicketTempDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_TEMP_DETALLE",
    payload: state,
  };
};
