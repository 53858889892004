import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Divider,
  Spin,
  Empty,
  Space,
  message,
  Popconfirm,
  Tooltip,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {
  ShoppingOutlined,
  DeleteOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

import moment from "moment";

// Importacion de constantes
import { colorSec, colorPrm } from "../../../constants";
import curStyle from "../../../css/GlobalCmp.module.css";

// Redux
import { useSelector } from "react-redux";

export default function TicketsShift(props) {
  const {
    myNumber,
    showTicketDet,
    setDrwDetalle,
    curCurrency,
    updateDetaTicket,
    cancelTicket,
  } = props;

  const { Title } = Typography;

  //useSelector para acceder al store
  //const ticketsShift = useSelector((state) => state.tickets.tickets);
  /*const ticketsDetalleShift = useSelector(
    (state) => state.ticketsDetalle.ticketsDetalle
  );*/

  const ticketsShift = useSelector(
    (state) => state.enviroment.enviroment.tickets_turno
  );
  const ticketsDetalleShift = useSelector(
    (state) => state.ticketsDetalle.ticketsDetalle
  );

  const [listaTickets, setListaTickets] = useState([]);
  const [cargando, setCargando] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // para mostrar el scroball cuando supere los 14 tickets
  const handleInfiniteOnLoad = () => {
    setLoading(true);
    if (ticketsShift.length > 2) {
      message.warning("Infinite List loaded all");
      setHasMore(false);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  //Actualizar la lista de Tickets
  useEffect(() => {
    setListaTickets(ticketsShift);
    updateDetaTicket([]);
  }, [ticketsShift]);

  //Imprimir el ticket
  const prnTicket = async (record) => {
    const detalleFltr = ticketsDetalleShift.filter((deta) => {
      return deta.id === record.id;
    });
    const savedTicket = {
      formato: 1,
      ticket_header: record,
      ticket_items: detalleFltr,
    };

    window.open(
      "https://api.aal-erp.com/pos/api/714?objeto=" +
        JSON.stringify(savedTicket),
      "_blank"
    );
  };
  //Fin imprimir Ticket

  return (
    <div style={{ minHeight: window.innerHeight - 170 }}>
      <div
        style={{
          padding: "8 24 0 0",
          minHeight: window.innerHeight - 200,
          overflow: "auto",
        }}
      >
        <Title
          level={4}
          style={{
            margin: 0,
            padding: 0,
            fontSize: 18,
            marginBottom: 15,
            textAlign: "center",
          }}
        >
          {"VENTAS DEL TURNO"}
        </Title>
        {Object.keys(ticketsShift).length !== 0 ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <List
              style={{ marginTop: -10 }}
              itemLayout="horizontal"
              loading={cargando}
              dataSource={listaTickets}
              renderItem={(item) => (
                <List.Item
                  style={{
                    marginTop: 1,
                    marginBottom: 1,
                    padding: 3,
                    height: 70,
                  }}
                >
                  <List.Item.Meta
                    title={
                      <span
                        style={{
                          color: colorPrm,
                          fontWeight: "600",
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            margin: 0,
                            padding: 0,
                            color: colorPrm,
                            fontSize: 16,
                          }}
                        >
                          {item.cliente}
                        </Title>
                      </span>
                    }
                    description={
                      <>
                        {item.plan_separe === 0 ? (
                          <Title
                            level={4}
                            style={{
                              margin: 0,
                              padding: 0,
                              color: colorSec,
                              fontSize: 16,
                            }}
                          >
                            {"TOTAL TICKET: " +
                              curCurrency.simbolo +
                              myNumber(1, item.total_ticket) +
                              " | PAGO CONTADO"}
                          </Title>
                        ) : item.plan_separe === 1 ? (
                          <Title
                            level={4}
                            style={{
                              margin: 0,
                              padding: 0,
                              color: colorSec,
                              fontSize: 16,
                            }}
                          >
                            {"TOTAL TICKET: " +
                              curCurrency.simbolo +
                              myNumber(1, item.total_ticket) +
                              " | PLAN SEPARE"}
                          </Title>
                        ) : (
                          <Title
                            level={4}
                            style={{
                              margin: 0,
                              padding: 0,
                              color: colorSec,
                              fontSize: 16,
                            }}
                          >
                            {"TOTAL TICKET: " +
                              curCurrency.simbolo +
                              myNumber(1, item.total_ticket) +
                              " | VENTA CREDITO"}
                          </Title>
                        )}
                      </>
                    }
                  />
                  <div style={{ marginRight: 10 }}>
                    <Space>
                      <Tooltip
                        title={"Ver el Detalle"}
                        placement="left"
                        color={colorSec}
                      >
                        <Button
                          className="secondary-color button-carrito-productos"
                          shape="circle"
                          size="large"
                          type="primary"
                          icon={<ShoppingOutlined />}
                          onClick={() => showTicketDet(item)}
                        />
                      </Tooltip>
                      <Tooltip
                        title={"Imprimir"}
                        placement="left"
                        color={colorSec}
                      >
                        <Button
                          className="secondary-color button-carrito-productos"
                          shape="circle"
                          size="large"
                          onClick={() => prnTicket(item)}
                          icon={<PrinterOutlined />}
                        />
                      </Tooltip>
                      <Popconfirm
                        placement="left"
                        title="DESEA CANCELAR LA VENTA?"
                        onConfirm={() => cancelTicket(item)}
                        okText="Si"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          size="large"
                          danger
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  </div>
                </List.Item>
              )}
            >
              {loading && hasMore && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 40,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Spin />
                </div>
              )}
            </List>
            <Divider style={{ marginTop: 3, paddingTop: 3, padding: 3 }} />
          </InfiniteScroll>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
              paddingLeft: 8,
              minHeight: window.innerHeight - 270,
            }}
            description={"SIN TICKETS REGISTRADOS"}
          />
        )}
      </div>
    </div>
  );
}
