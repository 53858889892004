const initialProps = {
  curTicketDetalle: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_DETALLE":
      return {
        ...state,
        curTicketDetalle: [...state.curTicketDetalle, action.payload],
      };
    case "UPDATE_TICKET_DETALLE":
      return {
        ...state,
        curTicketDetalle: action.payload,
      };
    case "REMOVE_TICKET_DETALLE":
      return {
        ...state,
        curTicketDetalle: action.payload,
      };
    default:
      return state;
  }
}
