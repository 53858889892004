const initialProps = {
  discartedDetalle: [],
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKETS_DISCARTED_DETALLE":
      return {
        ...state,
        discartedDetalle: action.payload,
      };
    case "UPDATE_TICKETS_DISCARTED_DETALLE":
      return {
        ...state,
        discartedDetalle: action.payload,
      };
    default:
      return state;
  }
}
