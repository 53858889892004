import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import MainHeader from "./Header";
import MainFooter from "./Footer";

//LeftMenu UserOptions
import LeftMenu from "./LeftMenu";

//Imaganes y logos
import Logof from "./Footer";
import {
  Layout,
  Row,
  Col,
  Drawer,
  Menu,
  Card,
  Typography,
  Divider,
  Tabs,
  Modal as ModalB,
} from "antd";
//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

/**
 * AALLayout Component
 * Para renderizar siempre Header & Footer
 * Se setea el State y todas las props en éste componente
 * Llega como parametro {children}
 * Para usarlo, se debe importar y entre las etiquetas
 * agregar el contenido de la pag como corresponde
 * <AALLayout> Content </AALLayout>
 */
export function AALLayout({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const { Content, Footer } = Layout;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("700px");
  const [titleModal, setTitleModal] = useState();
  const [activeKey, setActiveKey] = useState("1");
  const [placeDrawer, setPlaceDrawer] = useState(1);
  const [widthDrawer, setWidthDrawer] = useState(700);
  const [isDrawer, setIsDrawer] = useState(false);
  const [drwDetalle, setDrwDetalle] = useState(1);

  //Para hacer redirect
  const history = useHistory();

  //useSelector para acceder al store
  const currentComponent = useSelector(
    (state) => state.currentComponent.currentComponent
  );

  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const userOptions = useSelector(
    (state) => state.currentUser.currentUser.user_options
  );

  const userModules = useSelector(
    (state) => state.currentUser.currentUser.user_modules
  );

  const gstOpciones = useSelector(
    (state) => state.enviroment.enviroment.gst_opciones
  );

  const { Title } = Typography;
  const { confirm } = ModalB;
  const { TabPane } = Tabs;

  //Sse controlan los cambios de Tab
  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  //Abre el Drawer
  const openDrawer = (drwTipo, drwPalce, drwWidth) => {
    setPlaceDrawer(drwPalce);
    setWidthDrawer(drwWidth);
    setDrwDetalle(drwTipo);
    setIsDrawer(!isDrawer);
  };
  //Cierra el Drawer
  const closeDrawer = () => {
    setIsDrawer(false);
  };

  // MODALES/////////////////////
  const openModal = (title, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setIsVisibleModal(true);
    setTitleModal(title);
  };
  return (
    <Fragment>
      <Layout style={{ minHeight: "100vh" }}>
        <div style={{ height: "50" }}>
          <MainHeader
            setIsLoading={setIsLoading}
            currentUser={currentUser}
            openModal={openModal}
            curFecha={new Date()}
            titleHeader={currentComponent.titulo}
            userOptions={userOptions}
            openDrawer={openDrawer}
          />
        </div>
        <Layout className="site-layout" style={{ margin: 0 }}>
          <Content>
            <div
              className="site-drawer-render-in-current-wrapper"
              style={{
                height: window.innerHeight - 110,
                backgroundColor: "transparent",
                marginTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {children}
              <Drawer
                width={widthDrawer}
                placement={placeDrawer === 1 ? "left" : "right"}
                closable={false}
                visible={isDrawer}
                onClose={closeDrawer}
                getContainer={false}
                style={{ position: "absolute" }}
              >
                {drwDetalle === 1 ? (
                  <LeftMenu
                    userModules={userModules}
                    userOptions={userOptions}
                    gstOpciones={gstOpciones}
                    currentUser={currentUser}
                    closeDrawer={closeDrawer}
                  />
                ) : null}
              </Drawer>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              bottom: 0,
              maxHeight: 50,
              paddingBottom: 15,
              paddingTop: 15,
              backgroundColor: "#ffffff",
            }}
          >
            <MainFooter setIsLoading={setIsLoading} curFecha={new Date()} />
          </Footer>
        </Layout>
      </Layout>
    </Fragment>
  );
}
