export const createTicketFiveDetalleAction = (state) => {
  return {
    type: "CREATE_TICKET_FIVE_DETALLE",
    payload: state,
  };
};

export const updateTicketFiveDetalleAction = (state) => {
  return {
    type: "UPDATE_TICKET_FIVE_DETALLE",
    payload: state,
  };
};

export const removeTicketFiveDetalleAction = (state) => {
  return {
    type: "REMOVE_TICKET_FIVE_DETALLE",
    payload: state,
  };
};
