import React, { useState } from "react";
import {
  Modal,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateGanaderiaAction } from "../../../actions/ganaderiaActions";

//Styles
import "./CreateLot.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function CreateLot(props) {
  const { currentUser, closeModal, setIsLoading, myNumber } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idOrigen, setIdOrigen] = useState("SELECCIONE ORIGEN");
  const [idCampo, setIdCampo] = useState("SELECCIONE DESTINO");
  const [animales, setAnimales] = useState("");
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [data2, setData2] = useState("");
  const [fileList, setFileList] = useState([]);

  const { Option } = Select;

  const origenList = useSelector(
    (state) => state.ganaderiaEnv.ganaderiaEnv.origenes
  );
  const fieldsList = useSelector(
    (state) => state.ganaderiaEnv.ganaderiaEnv.campos
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) => dispatch(updateGanaderiaAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }

  //Se actualiza el nombre
  const updateCampo = async (value) => {
    setIdCampo(value);
  };

  //Se actualiza el nombre
  const updateOrigen = async (value) => {
    setIdOrigen(value);
  };

  const onChangeAnimales = (value) => {
    if (Number.parseInt(value) >= 0) {
      setAnimales(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          return item[0] !== "Fecha/Hora";
        });
        const bh_excel2 = [];
        excel.forEach(function (item, index, arr) {
          let cod = item[1].toString();
          let codigo = cod.split(" ");
          let dispositivo = codigo[1].substr(-4, 4) + codigo[2].trim();
          let key = index;
          let anm_codigo = dispositivo.trim();
          let grp_codigo = "";
          let sbg_codigo = "";
          let anm_sexo = "";
          let ctg_nombre = "";
          let anm_meses = 0;
          let anm_dias = 0;
          let anm_peso = item[4];

          let exceldata = {
            key: key,
            anm_codigo: anm_codigo,
            grp_codigo: grp_codigo,
            sbg_codigo: sbg_codigo,
            anm_sexo: anm_sexo,
            ctg_nombre: ctg_nombre,
            anm_meses: anm_meses,
            anm_dias: anm_dias,
            anm_peso: anm_peso,
          };

          bh_excel2.push(exceldata);
        });
        setData(bh_excel2);
      }
    });
  };

  //////////////////////////////////
  //Carga de los animales en campo
  const getDetalleCampo = async () => {
    if (idOrigen === "SELECCIONE ORIGEN") {
      showNotification(
        "topRight",
        2,
        "CARGAR ANIMALES",
        "DEBE REGISTRAR EL CAMPO DE ORIGEN"
      );
      return false;
    }
    setLoadingButton(true);
    let datos = await FRM.getCampoDet(currentUser, idOrigen);
    if (datos.type && datos.type > 0) {
      setDataDef(datos.listado);
      setData2(datos.listado);
      setAnimales(Number.parseInt(datos.listado.length));
      setData([]);
    } else {
      showNotification(2, "ERROR DE CONEXION", "Intente mas Tarde");
    }
    setLoadingButton(false);
  };
  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const uploadInvetoryWS = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(idOrigen) === 0 ||
      Number.parseInt(idCampo) === 0 ||
      Number.parseInt(animales) === 0 ||
      fechaEntr.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CARGAR CARAVANA",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        org_id: idOrigen,
        cmp_id: idCampo,
        cmb_animales: animales,
        ent_nota: nota,
        ent_fecha: fechaEntr,
        record_def: dataDef,
      };
      var val_inventory = await FRM.sendEntrada(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.ganaderia);
        showNotification(
          "topRight",
          4,
          "CARGAR CARAVANA",
          "REGISTRO DE CARAVANA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO Kg",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "20%",
      align: "right",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"REGISTRAR CAMBIO DE LOTE"}
      </Title>
      <Row gutter={16}>
        <Col span={4}>
          <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
        </Col>
        <Col span={8}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idOrigen}
            placeholder="CAMPO DE ORIGEN"
            onChange={updateOrigen}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {origenList.map((campo) => (
              <Option key={campo.cmp_id} value={campo.cmp_id}>
                {campo.cmp_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idCampo}
            placeholder="CAMPO DESTINO"
            onChange={updateCampo}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fieldsList.map((campo) => (
              <Option key={campo.id} value={campo.id}>
                {campo.cmp_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={animales}
            value={animales}
            onChange={onChangeAnimales}
            placeholder="Animales"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={2}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          display: "flex",
          alignContent: "left",
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <Button
          type="primary"
          shape="round"
          className={curStyle.btnBlueLight}
          loading={loadingButton}
          onClick={() => getDetalleCampo()}
        >
          <UploadOutlined /> ANIMALES EN CAMPO
        </Button>

        <Upload {...propis}>
          <Button
            type="primary"
            shape="round"
            className={curStyle.btnInfo}
            style={{
              marginLeft: 20,
            }}
          >
            <UploadOutlined /> ARCHIVO DEL BASTON
          </Button>
        </Upload>
      </div>
      <Table columns={header} dataSource={dataDef} bordered size="middle" />
      <Space>
        <Title
          level={4}
          style={{ color: colorSec, textAlign: "left", marginTop: 15 }}
        >
          {"Animales en CAMPO: " + myNumber(1, data2.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorGrn,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"Registros BASTON: " + myNumber(1, data.length)}
        </Title>
      </Space>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadInvetoryWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: dataDef.length > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR EL CAMBIO DE LOTE
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
