import React from "react";
import { Row } from "antd";

//Componentes propios
import Product from "../Product";
import Loading from "../../LoadGrey";

export default function ProductsCatalog(props) {
  const {
    myNumber,
    validateRows,
    listProducts,
    showDetails,
    curCurrency,
    updateBcode,
  } = props;

  return (
    <>
      <div
        style={{
          margin: "10px,10px,0px,10px",
          padding: "10px",
          backgroundColor: "#BABEBE",
        }}
      >
        <Row className="rowPrd" gutter={{ xs: 4, sm: 8, md: 12, lg: 24 }}>
          {listProducts.length === 0 ? (
            <Loading />
          ) : (
            <div
              style={{
                maxHeight: window.innerHeight - 150,
                minWidth: "100%",
              }}
            >
              {listProducts.map((product, index) => (
                <Product
                  key={index}
                  product={product}
                  validateRows={validateRows}
                  showDetails={showDetails}
                  myNumber={myNumber}
                  curCurrency={curCurrency}
                  updateBcode={updateBcode}
                />
              ))}
            </div>
          )}
        </Row>
      </div>
    </>
  );
}
