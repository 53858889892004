import React, { useState, useEffect } from "react";
import {
  Input,
  InputNumber,
  Button,
  Divider,
  Typography,
  Popconfirm,
  message,
  Row,
  Col,
} from "antd";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//Iconos y constantes
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { createMoveCashAction } from "../../../actions/Pos/moveCashActions";

//Componentes Personalizados
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import POS from "../../../utils/PosService";

//Estilos
import "./GestionEfectivo.scss";

export default function UpdateBarCode(props) {
  const { currentUser, titleModal, closeModal, varEffec, currentShift } = props;
  const [tituloModal, setTituloModal] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [tipoGestion, setTipoGestion] = useState(1);
  const [valGestion, setValGestion] = useState(0);
  const [notaGestion, setNotaGestion] = useState("");

  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch();

  //Crear y actualizar gestion de efectivo
  const createGestion = (state) => dispatch(createMoveCashAction(state));

  const { Title } = Typography;

  useEffect(() => {
    setTituloModal(
      titleModal === "Ingresos" ? "INGRESO DE EFECTIVO" : "SALIDA DE EFECTIVO"
    );
    setTipoGestion(titleModal === "Ingresos" ? 1 : 2);
  }, [varEffec]);

  const onChangeNota = (e) => {
    let nota = e.target.value;
    if (nota.length > 0) {
      setNotaGestion(nota.toUpperCase());
    } else {
      setNotaGestion("");
    }
  };

  const onChangeValor = (value) => {
    if (Number.parseInt(value) > 0) {
      setValGestion(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const registrarGestion = async () => {
    if (Number.parseInt(valGestion) === 0 || notaGestion.length === 0) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR GESTION",
        "Valores incorrectos... verifique!"
      );
      return false;
    }
    setLoadingButton(true);
    const gstEfectivo = {
      id: uuidv4(),
      fecha: moment(),
      turno: currentShift.id,
      user: currentUser.id,
      nombre: currentUser.nombre,
      nota: notaGestion,
      tipo: tipoGestion,
      estado: 0,
      valor: valGestion,
    };
    message.success("GESTION REGISTRADA LOCALMENTE", 1);
    const savedGst = {
      gst_efectivo: gstEfectivo,
      dato_adc: "asunto",
    };
    let val_gestion = await POS.sendEfectivo(currentUser, savedGst);

    if (Number.parseInt(val_gestion.type) > 0) {
      gstEfectivo.estado = 1;
      createGestion(gstEfectivo);
      showNotification(
        "topRight",
        4,
        "GESTION DE EFECTIVO",
        "REGISTRO EXITOSO"
      );
    } else {
      //createGestion(gstEfectivo);
      showNotification(
        "topRight",
        2,
        "GESTION DE EFECTIVO",
        val_gestion.message,
        0
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <>
      <Title
        level={3}
        style={{
          padding: 2,
          margin: 2,
          fontWeight: 600,
          textAlign: "center",
          color: tipoGestion === 1 ? CST.colorGrn : CST.colorSec,
        }}
      >
        {tituloModal}
      </Title>
      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <Row gutter={[8, 8]}>
        <Col span={16}>
          <Input
            defaultValue={""}
            value={notaGestion}
            onChange={onChangeNota}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            max={10000000}
            defaultValue={0}
            value={valGestion}
            onChange={onChangeValor}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 3, paddingTop: 3, marginBottom: 5 }} />
      <Popconfirm
        placement="top"
        title="¿ Resgistrar el Movimiento ?"
        onConfirm={registrarGestion}
        okText="Registrar"
        cancelText="Cancelar"
        okType="primary"
        icon={
          tipoGestion === 1 ? (
            <PlusCircleOutlined style={{ color: CST.colorPrm }} />
          ) : (
            <MinusCircleOutlined style={{ color: CST.colorPrm }} />
          )
        }
      >
        <Button
          type="primary"
          className={
            tipoGestion === 1 ? curStyle.btnSuccess : curStyle.btnOrange
          }
          shape="round"
          block
          loading={loadingButton}
        >
          {tipoGestion === 1 ? "REGISTRAR INGRESO" : "REGISTRAR SALIDA"}
        </Button>
      </Popconfirm>
    </>
  );
}
