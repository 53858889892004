import React from "react";
import { Row, Col, Spin } from "antd";
import bg3 from "../../assets/img/bg-3.jpg";
import logo from "../../assets/img/logo-3.png";

import "./Loading.scss";

export default function Loading() {
  return (
    <div
      className="loading"
      style={{
        backgroundImage: `url('${bg3}')`,
        height: "100vh",
        backgroundSize: "cover",
        width: "100vw",
      }}
    >
      <Row>
        <Col span={24} style={{ alignItems: "center" }}>
          <img src={logo} alt="imagen" style={{ marginBottom: "20px" }} />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ alignItems: "center" }}>
          <Spin size="large" />
        </Col>
      </Row>
    </div>
  );
}
