import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateGanaderiaAction } from "../../../actions/ganaderiaActions";

//Styles
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

export default function TiposAactividadForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.tac_id);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setCurId(record.tac_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) => dispatch(updateGanaderiaAction(state));

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendMaestro(
      currentUser,
      curId,
      "TipoActividad",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.ganaderia);
      showNotification(
        "topRight",
        4,
        "GESTION DE TIPOS DE ACTIVIDAD",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE TIPOS DE ACTIVIDAD",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="tipoactividad"
      onFinish={guardarCambios}
      initialValues={{
        tac_nombre: record.tac_id > 0 ? record.tac_nombre : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.id > 0
            ? "ACTUALIZAR TIPO DE ACTIVIDAD"
            : "AGREGAR TIPO DE ACTIVIDAD"}
        </Title>
      </Form.Item>
      <Form.Item
        name="tac_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.tac_id > 0 ? record.tac_nombre : ""}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
