const initialProps = {
  isLogued: false,
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "UPDATE_STATE_LOGUED":
      return {
        ...state,
        isLogued: action.payload,
      };
    default:
      return state;
  }
}
