import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Divider,
  Spin,
  Empty,
  Space,
  message,
  Tooltip,
  Typography,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";

// Importacion de constantes
import { colorSec, colorPrm } from "../../../constants";
import curStyle from "../../../css/GlobalCmp.module.css";

// Redux
import { useSelector } from "react-redux";

export default function Deptors(props) {
  const { myNumber, curCurrency } = props;

  const { Title } = Typography;

  //useSelector para acceder al store
  const prepayerList = useSelector(
    (state) => state.enviroment.enviroment.pedidos
  );
  const ordersDet = useSelector(
    (state) => state.enviroment.enviroment.pedidos_det
  );

  const [listaDeudores, setListaDeudores] = useState([]);
  const [cargando, setCargando] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // para mostrar el scroball cuando supere los 14 tickets
  const handleInfiniteOnLoad = () => {
    setLoading(true);
    if (prepayerList.length > 2) {
      message.warning("Infinite List loaded all");
      setHasMore(false);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  //Actualizar la lista de Tickets
  useEffect(() => {
    setListaDeudores(prepayerList);
  }, [prepayerList]);

  return (
    <div style={{ minHeight: window.innerHeight - 170 }}>
      <div
        style={{
          padding: "8 24 0 0",
          minHeight: window.innerHeight - 200,
          overflow: "auto",
        }}
      >
        <Title
          level={4}
          style={{
            margin: 0,
            padding: 0,
            fontSize: 18,
            marginBottom: 15,
            textAlign: "center",
          }}
        >
          {"PLANES SEPARE"}
        </Title>
        {Object.keys(prepayerList).length !== 0 ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <List
              style={{ marginTop: -10 }}
              itemLayout="horizontal"
              loading={cargando}
              dataSource={listaDeudores}
              renderItem={(item) => (
                <List.Item
                  style={{
                    marginTop: 1,
                    marginBottom: 1,
                    padding: 3,
                    height: 70,
                  }}
                >
                  <List.Item.Meta
                    title={
                      <span
                        style={{
                          color: colorPrm,
                          fontWeight: "600",
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            margin: 0,
                            padding: 0,
                            color: colorPrm,
                            fontSize: 16,
                          }}
                        >
                          {item.ped_nombre}
                        </Title>
                      </span>
                    }
                    description={
                      <>
                        <Title
                          level={4}
                          style={{
                            margin: 0,
                            padding: 0,
                            color: colorSec,
                            fontSize: 16,
                          }}
                        >
                          {"VALOR COMPRA: " +
                            curCurrency.simbolo +
                            myNumber(1, item.ped_total)}
                        </Title>
                      </>
                    }
                  />
                  <div style={{ marginRight: 10 }}>
                    <Space>
                      <Tooltip
                        title={"Ver el Detalle"}
                        placement="left"
                        color={colorSec}
                      >
                        <Button
                          className="secondary-color button-carrito-productos"
                          shape="circle"
                          size="large"
                          type="primary"
                          icon={<SearchOutlined />}
                          onClick={() =>
                            message.success(
                              "OPCION EN DESARROLLO... PRONTO DISPONIBLE",
                              2
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        title={"Ver Abonos"}
                        placement="left"
                        color={colorSec}
                      >
                        <Button
                          className="secondary-color button-carrito-productos"
                          shape="circle"
                          size="large"
                          type="primary"
                          icon={<CalendarOutlined />}
                          onClick={() =>
                            message.success(
                              "OPCION EN DESARROLLO... PRONTO DISPONIBLE",
                              2
                            )
                          }
                        />
                      </Tooltip>
                    </Space>
                  </div>
                </List.Item>
              )}
            >
              {loading && hasMore && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 40,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Spin />
                </div>
              )}
            </List>
            <Divider style={{ marginTop: 3, paddingTop: 3, padding: 3 }} />
          </InfiniteScroll>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
              paddingLeft: 8,
              minHeight: window.innerHeight - 270,
            }}
            description={"SIN PLANES REGISTRADOS"}
          />
        )}
      </div>
    </div>
  );
}
