import React, { useState } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Importo Store y Provider desde Redux
import store from "./store";
import { Provider } from "react-redux";

import "./App.css";

//Paginas
import Loading from "./components/Loading";
import Login from "./pages/login";
import Maestros from "./pages/Maestros/index";
import Invoice from "./pages/Pos/invoice";
import Farmboard from "./pages/Farm/frmboard";
import Error404 from "./pages/error404";

export default function App() {
  const { Content } = Layout;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Provider store={store}>
      <Layout>
        <Router>
          <Content>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Switch>
                  <Route path="/" exact={true}>
                    <Login setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/invoice" exact={true}>
                    <Invoice setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmboard" exact={true}>
                    <Farmboard setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/maestros" exact={true}>
                    <Maestros setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="*">
                    <Error404 />
                  </Route>
                </Switch>
              </>
            )}
          </Content>
        </Router>
      </Layout>
    </Provider>
  );
}
