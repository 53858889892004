const initialProps = {
  ticketOne: {
    id: "0",
    totalTicket: 0,
    cliente: "",
  },
};

export default function (state = initialProps, action) {
  switch (action.type) {
    case "CREATE_TICKET_ONE":
      return {
        ...state,
        ticketOne: action.payload,
      };
    case "UPDATE_TICKET_ONE":
      return {
        ...state,
        ticketOne: action.payload,
      };
    default:
      return state;
  }
}
