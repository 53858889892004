import { showNotification } from "./ShowNotification";
const URL_USER = "https://api.aal-erp.com/pos/api";

class POS {
  //Datos de entorno
  getEnviroment(user, turno) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("id_turno", turno);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Ticket
  sendTicket(user, ticket) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("ticket", JSON.stringify(ticket));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Tickets
  sendGestion(user, packDatos, turno) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id_turno", turno);
    uploadData.append("packDatos", JSON.stringify(packDatos));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/4`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Tickets
  updateCodBar(user, art_id, art_codbar, turno) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("art_id", art_id);
    uploadData.append("art_codbar", art_codbar);
    uploadData.append("id_turno", turno);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/3`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Movimientos de Efectivo
  sendEfectivo(user, packDatos) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("packDatos", JSON.stringify(packDatos));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/5`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Elimar gestion, Ticket o Eefectivo
  deleteGestion(user, tipo, idGestion, turno) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id_turno", turno);
    uploadData.append("tipo", tipo);
    uploadData.append("idGestion", idGestion);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/6`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Backuos
  sendBackups(user, packDatos, turno) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id_turno", turno);
    uploadData.append("packDatos", JSON.stringify(packDatos));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/7`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }
}

export default new POS();
