import React from "react";
import Helmet from "react-helmet";
import { PROJECT_NAME } from "../constants";

/**
 * TitlePage Component para poner el titulo
 * a cada pagina del Proyecto.
 * Se importa en cada pagina y se envía por props el title
 * <TitlePage title="Nombre Pagina"/>
 */
export function TitlePage(props) {
  const { title } = props;
  return (
    <div>
      <Helmet>
        <title>
          {title} | {PROJECT_NAME}
        </title>
        <link rel="icon" type="image/png" href="favicon.ico" />
      </Helmet>
    </div>
  );
}
