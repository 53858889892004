export const createTicketThreeAction = (state) => {
  return {
    type: "CREATE_TICKET_THREE",
    payload: state,
  };
};

export const updateTicketThreeAction = (state) => {
  return {
    type: "UPDATE_TICKET_THREE",
    payload: state,
  };
};
