import React from "react";
import { Row, Col } from "antd";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

import bg3 from "../../../assets/img/bg-3.jpg";
import logo from "../../../assets/img/logo-grey-small.png";

import "./ProductDetails.scss";

export default function ProductDetails(props) {
  const { closeModal } = props;

  const product = useSelector((state) => state.currentProduct.currentProduct);

  console.log(product);

  return (
    <div
      className="loading"
      style={{
        backgroundImage: `url('${bg3}')`,
        height: "100%",
        backgroundSize: "cover",
        width: "660px",
        marginTop: 20,
      }}
    >
      <Row>
        <Col style={{ alignItems: "center" }}>
          <img
            src={logo}
            alt="Imagen"
            style={{ marginBottom: "20px" }}
            onClick={() => closeModal()}
          />
        </Col>
      </Row>
    </div>
  );
}
