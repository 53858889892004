export const ERROR_FETCH = { type: 0, message: "ERROR INESPERADO" };
export const URL_SERVICE = "https://api.aal-erp.com/erp/api";
export const AVATAR_DEFAULT =
  "https://brokerhood.com/files/brokerhood/avatar/avatar-1.png";
export const URL_AVATAR = "https://pos.aal-erp.com/files/pos/avatar/";

export const colorPrm = "#0f70b7";
export const colorSec = "#315cab";
export const colorSuc = "#2ecc71";
export const borderSuc = "#27ae60";
export const colorWar = "#f1c40f";
export const borderWar = "#f39c12";
export const colorInf = "#1abdaf";
export const borderInf = "#16a195";
export const colorDef = "#ecf0f1";
export const borderDef = "#bdc3c7";
export const colorInv = "#95a5a6";
export const borderInv = "#7f8c8d";
export const colorGrn = "#1abdaf";

export const CURRENCY = "COP";
export const SYMBOL = "$";
export const PROJECT_NAME = "ERP - AAL-Team";
